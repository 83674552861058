import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const UpComing = () => {
  // const options = {
  //   margin: 30,
  //   responsiveClass: true,
  //   nav: true,

  //   autoplay: true,
  //   navText: ["<", ">"],
  //   smartSpeed: 1000,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     400: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 1,
  //     },
  //     1000: {
  //       items: 1,
  //     },
  //   },
  // };
  return (
    <>
      <div className="outer-border outer-overflow-show">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4">
              <ul className="month-select">
                <li>
                  <span>
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  </span>
                  JANUARY 2023
                  <span>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-lg-8">
              <div className="calender-list">
                <table className="calendar">
                  <thead>
                    <tr>
                      <th className="ps-1">Sun</th>
                      <th>Mon</th>
                      <th>Tue</th>
                      <th>Wed</th>
                      <th>Thu</th>
                      <th>Fri</th>
                      <th>Sat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="prev-month">30</td>
                      <td>1</td>
                      <td>2</td>
                      <td>3</td>
                      <td>4</td>
                      <td>5</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>8</td>
                      <td>9</td>
                      <td>10</td>
                      <td>11</td>
                      <td>12</td>
                      <td>13</td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>15</td>
                      <td>16</td>
                      <td className="active">17</td>
                      <td>18</td>
                      <td>19</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>22</td>
                      <td>23</td>
                      <td>24</td>
                      <td>25</td>
                      <td>26</td>
                      <td>27</td>
                    </tr>
                    <tr>
                      <td className="border-remove">28</td>
                      <td className="border-remove">29</td>
                      <td className="border-remove">30</td>
                      <td className="border-remove">31</td>
                      <td className="prev-month border-remove">1</td>
                      <td className="prev-month border-remove">2</td>
                      <td className="prev-month border-remove">3</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="hr-color-changes"></div>
            <div className="col-lg-12 px-0 d-none">
              <div className="card-body my-3 mx-0 ">
                <div className="table-responsive">
                  <table className="table-user-data">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Max-size</th>
                        <th>Location</th>
                        <th>Booking</th>
                      </tr>
                    </thead>
                    <tbody className="body-text changes-top triangle-top">
                      <tr>
                        <td colSpan="6" style={{ border: '0px solid' }}>
                          <table className="crop table-booking-user table-pad">
                            <tbody>
                              <tr>
                                <td className="f-w">
                                  INDIVIDUAL INDIVIDUAL INDIVIDUAL
                                </td>
                                <td className="s-w">1234-52182</td>
                                <td className="t-w">Active</td>
                                <td className="fo-w">
                                  billysquire@outlook.com
                                </td>
                                <td className="fi-w">404.674.4848</td>
                                <td className="si-w">404.674.4848</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees">
                  <h3 className="card-top-2">UPCOMING</h3>
                </div>
                <div className="triangle-top">
                  <div className="body-text up-qq">
                    <div className="table-responsive w-100">
                      <table className="table table-time-gun-create mobile-responsive">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Max-size</th>
                            <th>Location</th>
                            <th>Booking</th>
                          </tr>
                        </thead>
                        <tbody className="table-time-gun-create__old-even ">
                          <tr>
                            <td data-label="Name">AJAY</td>
                            <td data-label="Date">27/07/97</td>
                            <td data-label="Time">23:00</td>
                            <td data-label="Max-Size">22</td>
                            <td data-label="Location">Fazilka</td>
                            <td data-label="Booking">No</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpComing;
