import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../Utils/axiosInstance';
import * as Yup from 'yup';
import { FORGOT_PASSWORD_ENDPOINT } from '../Utils/Endpoints';
import ButtonLoader from '../Components/ButtonLoader';

// Validation schema
const ResetSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const navigate = useNavigate();

  const validate = () => {
    try {
      setGeneralError('');
      ResetSchema.validateSync({ email }, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(FORGOT_PASSWORD_ENDPOINT, {
        email,
      });

      if (response.data.code === 200) {
        setSuccessMsg('');
        navigate('/confirm-password', {
          state: {
            token: response?.data?.data?.confirmation_token,
            email: email,
            message: response.data.message,
          },
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="sign-in">
        <div className="text-center">
          <img
            src="../assets/images/webside.webp"
            alt=""
            className="sign-logo"
          />
        </div>
        <h3>Reset Password</h3>
        {generalError && <p className="error">{generalError}</p>}
        {successMsg && <p className="successfully">{successMsg}</p>}
        <div className="triangle-card sign-in-card">
          <form className="sign-form" onSubmit={handleSubmit}>
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email address*
            </label>
            <div className="input-search-items tickets">
              <input
                type="email"
                className="form-control input-theme mb-0"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </div>

            <div className=" mt-3">
              <ButtonLoader
                type="submit"
                bgChange={`white-bg`}
                active="orange-outline"
                isLoading={isLoading}
              >
                Reset Password
              </ButtonLoader>
              {/* <button type="submit" className="white-bg">
                <Link to="/confirm-password" className="link-a">
                Reset Password
                </Link>
              </button> */}
            </div>
          </form>
        </div>
        <Link to="/" className="pass-far">
          Back to login
        </Link>
      </div>
    </>
  );
};

export default ResetPassword;
