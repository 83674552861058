import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import ButtonAdd from '../Components/ButtonAdd';
import HexagonSize from '../Components/HexagonSize';
import HexLine from '../Components/HexLine';
import axiosInstance from '../Utils/axiosInstance';
import Logger from '../Utils/Logger';
import {
  PROFICIENCY_EXAM_UPDATE_ENDPOINT,
  USER_PROFICIENCY_EXAM_LIST_ENDPOINT,
} from '../Utils/Endpoints';
import { getItem } from '../Utils/LocalStorage';
import SkeletonLoader from '../Components/SkeletonLoader';
import ButtonLoader from '../Components/ButtonLoader';
import Toast from '../Utils/Toast';
import { RANGE_CLASS } from '../Utils/ModelClasses';
import RangeNotFound from '../Components/RangeNotFound';

const CpeValidationNew = () => {
  const [examList, setExamList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const rangeID = getItem('rangeId');
  const examProficiency = getItem('examProficiency');
  const [selectedData, setSelectedData] = useState({});
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [generalError, setGeneralError] = useState('');

  useEffect(() => {
    fetchExamList();
  }, []);

  const fetchExamList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        USER_PROFICIENCY_EXAM_LIST_ENDPOINT +
          `?owner_class=${RANGE_CLASS}&owner_id=${rangeID}&user_id=${examProficiency?.user_id}`
      );
      if (response.data.code === 200) {
        setExamList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleUpdateStatus = (proficiency) => {
    setSelectedData(proficiency);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      setGeneralError('');
      setIsLoadingButton(true);
      const response = await axiosInstance.patch(
        PROFICIENCY_EXAM_UPDATE_ENDPOINT,
        {
          owner_class: RANGE_CLASS,
          owner_id: rangeID,
          id: selectedData?.id,
          status: 'CERTIFIED', // Required|String|CERTIFIED, PENDING, REJECTED
        }
      );
      if (response.data.code === 200) {
        const modalClose = document.getElementsByClassName('close-FPE-modal');
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        await fetchExamList();
        Toast.success(
          response.data.message
            ? response.data.message
            : 'Proficiency exam status updated. ',
          {
            autoClose: 2000,
          }
        );
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoadingButton(false);
    }
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            {/* <h3 className="all-top-heading">
                COUNTER PROFICIENCY EXAMINATION
              </h3> */}
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <div className="back-listing-tab">
                    <Link to="/ranges/user-details" className="button-width">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2">
                      FIREARM PROFICIENCY EXAMINATION
                    </h3>
                  </div>
                  <div className="all-date-selected mt-3 new-back-btn-add">
                    <Link
                      to="/ranges/user-details"
                      className="button-width b-p"
                    >
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                  </div>
                </div>

                <div className="triangle-top">
                  <div className="body-text ">
                    <div className="tab-content ">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-home"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="examination d-flex align-items-center">
                              {examList?.length > 0 && (
                                <div className="pe-3">
                                  <HexagonSize
                                    imageUser={
                                      examList[0]?.user?.profile_picture
                                        ? examList[0]?.user?.profile_picture
                                            ?.fullUrl
                                        : '../assets/images/user-1.jpeg'
                                    }
                                    hexBg="bg-black bg-org"
                                  />

                                  <h4 className="sub-heading heading-color-changes mb-4 mb-lg-0">
                                    {examList[0]?.user?.name}
                                  </h4>
                                </div>
                              )}
                              {examList?.length === 0 && !isLoading && (
                                <RangeNotFound
                                  title="Firearm Proficiency Examination"
                                  isInfo={true}
                                />
                              )}
                              {examList?.length > 0 && (
                                <div className="table-responsive w-100">
                                  <table className="table user-table-id  mobile-responsive mobile">
                                    <thead>
                                      <tr>
                                        <th>FPE TYPE</th>
                                        <th className="text-start">
                                          DATE REQUESTED
                                        </th>
                                        <th>FPE ASSESSMENT</th>
                                        <th>CERTIFICATION</th>
                                        <th>RANGE ADMIN</th>
                                      </tr>
                                    </thead>
                                    <tbody className="mobile__old-even mobile__res-card-2">
                                      {examList?.length > 0 &&
                                        examList.map((item, index) => (
                                          <tr key={index}>
                                            <td data-label="FPE Type">
                                              <div className="r-n-img r-n-img-2">
                                                <p className="image-gg">
                                                  <img
                                                    src={
                                                      item?.entity_has_proficiency_type
                                                        ? item
                                                            ?.entity_has_proficiency_type
                                                            ?.logo?.fullUrl
                                                        : '../assets/icon/handgun.svg'
                                                    }
                                                    alt=""
                                                    className="image-gg"
                                                  />
                                                </p>
                                                <p>
                                                  {
                                                    item
                                                      ?.entity_has_proficiency_type
                                                      .proficiency_type?.name
                                                  }
                                                </p>
                                              </div>
                                            </td>
                                            {/* <td data-label="">
                                              <p className="color-black">
                                                HANDGUN
                                              </p>
                                            </td> */}
                                            <td data-label="Date Requested">
                                              <p className="text-left time-xx">
                                                {item.created_at}
                                              </p>
                                            </td>
                                            <td data-label="FPE Assessment">
                                              <p
                                                className={
                                                  'head-dote ' +
                                                  (item?.examDetails?.valid ===
                                                  item?.examDetails?.total
                                                    ? ' dote-color-change'
                                                    : '')
                                                }
                                              >
                                                HANDLING
                                                <span>
                                                  {item?.examDetails?.valid}/
                                                  {item?.examDetails?.total}
                                                </span>
                                              </p>
                                              {/* <p className="head-dote ">
                                              SAFETY
                                              <span>4/5</span>
                                            </p> */}
                                            </td>
                                            <td
                                              data-label="Certification"
                                              className="table-button-width"
                                            >
                                              {item?.status === 'PENDING' && (
                                                <Link
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#exampleModal"
                                                  onClick={() =>
                                                    handleUpdateStatus(item)
                                                  }
                                                >
                                                  <ButtonRemoveImage
                                                    Heading={item.status}
                                                    bgChange="bg-orange"
                                                  />
                                                </Link>
                                              )}
                                              {item?.status === 'CERTIFIED' && (
                                                <Link>
                                                  <ButtonRemoveImage
                                                    Heading={item.status}
                                                    bgChange="success-bg-color success-bg-fill"
                                                    active="success-outline"
                                                  />
                                                </Link>
                                              )}
                                            </td>
                                            <td data-label="Range Admin">1</td>
                                          </tr>
                                        ))}
                                      {isLoading && (
                                        <SkeletonLoader
                                          type="table"
                                          rows={6}
                                          columns={6}
                                        />
                                      )}
                                      {/* <tr>
                                        <td data-label="FPE Type">
                                          <img
                                            src="../assets/icon/rifle.svg"
                                            alt=""
                                            className="img-set-gun-right"
                                          />
                                        </td>
                                        <td data-label="">
                                          <p className="color-black">RIFLE</p>
                                        </td>
                                        <td data-label="Date Requested">
                                          <p className="text-left">
                                            January 1, 2023 <br /> 10:21 AM CST
                                          </p>
                                        </td>
                                        <td data-label="FPE Assessment">
                                          <p className="head-dote dote-color-change">
                                            HANDLING
                                            <span>2/7</span>
                                          </p>
                                          <p className="head-dote dote-color-change">
                                            SAFETY
                                            <span>3/4</span>
                                          </p>
                                        </td>
                                        <td
                                          data-label="Certification"
                                          className="table-button-width"
                                        >
                                          <Link
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                          >
                                            <ButtonRemoveImage
                                              Heading="CERTIFIED"
                                              bgChange="success-bg-color"
                                              active="success-outline"
                                            />
                                          </Link>
                                        </td>
                                        <td data-label="Range Admin">
                                          <p className="color-black text-right center-left">
                                            Jeff J.
                                          </p>
                                          <p className="text-right center-left">
                                            1 / 1 / 2023
                                          </p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td data-label="FPE Type">
                                          <img
                                            src="../assets/icon/shotgun.svg"
                                            alt=""
                                            className="img-set-gun-right"
                                          />
                                        </td>
                                        <td data-label="">
                                          <p className="color-black">SHOTGUN</p>
                                        </td>
                                        <td data-label="DATE REQUESTED">
                                          <p className="text-left">
                                            January 1, 2023 <br /> 10:21 AM CST
                                          </p>
                                        </td>
                                        <td data-label="FPE ASSESSMENT">
                                          <p className="head-dote dote-color-change">
                                            HANDLING
                                            <span>2/7</span>
                                          </p>
                                          <p className="head-dote dote-color-change">
                                            SAFETY
                                            <span>3/4</span>
                                          </p>
                                        </td>
                                        <td data-label="CERTIFICATION">
                                          <Link
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                          >
                                            <ButtonRemoveImage
                                              Heading="CERTIFIED"
                                              bgChange="success-bg-color"
                                              active="success-outline"
                                            />
                                          </Link>
                                        </td>
                                        <td data-label="RANGE ADMIN">
                                          <p className="color-black text-right center-left">
                                            Jeff J.
                                          </p>
                                          <p className="text-right center-left">
                                            1 / 1 / 2023
                                          </p>
                                        </td>
                                      </tr> */}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content modal-table">
            <div className="modal-header table-header">
              <div aria-label="Close" data-bs-dismiss="modal">
                <HexLine
                  hexBg="bg-bo-li-th"
                  hexOuterLine="bg-dark-bc"
                  imageUserTwo="../assets/icon/cross_icon.svg"
                />
              </div>
              <h5>FIREARM PROFICIENCY EXAMINATION</h5>
            </div>
            <div className="modal-body identity-card">
              <div className="identity-card__body identity-card__border">
                <HexagonSize
                  hexOuterLine="bg-trans"
                  imageUser={
                    selectedData?.user?.profile_picture?.fullUrl
                      ? selectedData?.user?.profile_picture?.fullUrl
                      : '../assets/images/user-1.jpeg'
                  }
                  hexBg="bg-black bg-org m-auto"
                />
                <h4>{selectedData?.user?.name}</h4>
              </div>
              <div className="identity-card__body ">
                <HexLine
                  hexBg="bg-bo-li-th-w m-auto"
                  hexOuterLine="bg-trans"
                  imageUserTwo={
                    selectedData?.entity_has_proficiency_type
                      ? selectedData?.entity_has_proficiency_type?.logo?.fullUrl
                      : '../assets/icon/handgun.svg'
                  }
                  imgSizeTwo="today-size"
                />

                <h4>
                  {
                    selectedData?.entity_has_proficiency_type?.proficiency_type
                      ?.name
                  }
                </h4>
              </div>
            </div>
            {generalError && (
              <p className="error text-center">{generalError}</p>
            )}
            <div className="modal-footer identity-button pe-md-3 ">
              <Link data-bs-dismiss="modal" className="close-FPE-modal">
                <ButtonRemoveImage
                  Heading="INCOMPLETE"
                  activeColor="white-color"
                  bgChange="bg-dark-gray"
                  active="orange-outline"
                />
              </Link>
              <ButtonLoader
                Heading="APPROVED"
                active="success-outline"
                bgChange="bg-dark-gray-success delete-w"
                isLoading={isLoadingButton}
                onClick={handleClick}
              >
                APPROVED
              </ButtonLoader>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CpeValidationNew;
