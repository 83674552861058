import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ImageUploadInput = ({ setFile, handleDeleteImage, imageData }) => {
  const [preview, setPreview] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(event.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  useEffect(() => {
    if (imageData?.id) {
      setPreview(imageData?.fullUrl);
    } else {
      setPreview(null);
    }
  }, [imageData]);

  const handleDelete = () => {
    setPreview(null);
    setFile(null);
    if (imageData?.id) {
      handleDeleteImage(imageData.id);
    }
  };

  return (
    <>
      <div className="input__image">
        <div className="file-input">
          <input type="file" accept="image/*" onChange={handleFileChange} />
          <div className="preview">
            {preview ? (
              <div className="image-container">
                <img src={preview} alt="File Preview" />
                <div className="overlay__v">
                  <button className="delete-button" onClick={handleDelete}>
                    <img src="../assets/icon/delete.svg" alt="" />
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="upload-image-text-data">
                  <h4>Logo Image</h4>
                  <p>
                    Selected files are not actually uploaded. Recommended Size
                    1280 X 384
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ImageUploadInput.propTypes = {
  setFile: PropTypes.func,
  handleDeleteImage: PropTypes.func,
  imageData: PropTypes.object,
};
export default ImageUploadInput;
