import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SkeletonLoader from '../Components/SkeletonLoader';
import { saveItem } from '../Utils/LocalStorage';
import DeleteModal from '../Components/DeleteModal';
import CreateRangesModal from '../Components/CreateRangesModal';
import RangeNotFound from '../Components/RangeNotFound';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRange } from '../Redux/features/ranges/rangeSlice';

const RangeLocations = () => {
  const [isLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [selectedRange, setSelectedRange] = useState({});
  const dispatch = useDispatch();
  const rangeList = useSelector((state) => state.range.rangeList);

  const handleUpdateList = async () => {
    await dispatch(fetchRange());
  };

  const handleView = (rangeId) => {
    saveItem('rangeId', rangeId);
  };

  const handleEditRangeClick = (range) => {
    setSelectedRange(range);
  };

  useEffect(() => {
    dispatch(fetchRange());
  }, []);
  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2 set-hh">Range</h3>
                  {/* <div className="all-date-selected">
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                  </div> */}
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      {rangeList?.length > 0 && (
                        <div className="table-responsive W-100 ">
                          <table className="table table-user-gan mobile-responsive mobile up">
                            <thead>
                              <tr>
                                <th className="d-block d-md-none"></th>
                                <th>Name</th>
                                <th>Location</th>
                                <th>Bays</th>
                                <th>Lanes</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody className="mobile__old-even mobile__res-card-2">
                              {rangeList?.length > 0 &&
                                rangeList.map((range, index) => (
                                  <tr key={index}>
                                    <td className="d-block d-md-none tb-1">
                                      <img
                                        src={
                                          range?.logo?.fullUrl
                                            ? range?.logo?.fullUrl
                                            : '../assets/images/exterior-1.jpg'
                                        }
                                        alt=""
                                        className="exterior"
                                      />
                                    </td>
                                    <td
                                      data-label="Range"
                                      className="table-w-set"
                                    >
                                      <div className="r-n-img">
                                        <div className="exterior d-none d-md-block">
                                          <img
                                            src={
                                              range?.logo?.fullUrl
                                                ? range?.logo?.fullUrl
                                                : '../assets/images/exterior-1.jpg'
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <p>{range?.name}</p>
                                      </div>
                                    </td>
                                    <td
                                      data-label="Location"
                                      className="range-width"
                                    >
                                      {range?.address?.addressString}
                                    </td>
                                    <td data-label="Bays">
                                      {range?.bays_count}
                                    </td>
                                    <td data-label="Lanes">
                                      {range?.lanes_count}
                                    </td>
                                    <td data-label="Actions">
                                      <div className="mt-0 mt-lg-1 justify-content-lg-center d-flex align-items-center gap-2 gap-lg-2">
                                        <Link
                                          to="/ranges/manage"
                                          onClick={() => handleView(range.id)}
                                          className="color-theme border-text"
                                        >
                                          {/* <span className="mob-tab-view-2">
                                            view
                                          </span> */}
                                          <img
                                            src="../assets/icon/view-eye.svg"
                                            alt=""
                                            style={{
                                              width: '25px',
                                              // marginRight: ' 5px',
                                            }}
                                          />
                                        </Link>
                                        <Link
                                          className="or-col"
                                          data-bs-toggle="modal"
                                          data-bs-target="#createRanges"
                                          onClick={() =>
                                            handleEditRangeClick(range)
                                          }
                                        >
                                          <img
                                            src="../assets/icon/edit.svg"
                                            alt="edit"
                                            style={{
                                              width: '23px',
                                            }}
                                          />
                                        </Link>
                                        <Link
                                          to="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteModal"
                                          className="dan-col"
                                          onClick={() =>
                                            setSelectedData({
                                              id: range.id,
                                              type: 'range_list',
                                            })
                                          }
                                        >
                                          <img
                                            className="ddd-card"
                                            src="../assets/icon/delete.svg"
                                            alt="delete"
                                            style={{
                                              width: '25px',
                                            }}
                                          />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {isLoading && rangeList?.length === 0 && (
                        <SkeletonLoader />
                      )}
                      {rangeList?.length === 0 && !isLoading && (
                        <RangeNotFound title="Range" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        selectedData={selectedData}
        handleUpdateList={handleUpdateList}
      />
      <CreateRangesModal
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        updateList={handleUpdateList}
      />
    </>
  );
};

export default RangeLocations;
