import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import HexagonSize from '../Components/HexagonSize';
import HexLine from '../Components/HexLine';
import Logger from '../Utils/Logger';
import axiosInstance from '../Utils/axiosInstance';
import {
  PROFICIENCY_EXAM_LIST_ENDPOINT,
  PROFICIENCY_EXAM_UPDATE_ENDPOINT,
} from '../Utils/Endpoints';
import SkeletonLoader from '../Components/SkeletonLoader';
import { getItem, saveItem } from '../Utils/LocalStorage';
import RangeNotFound from '../Components/RangeNotFound';
import ButtonLoader from '../Components/ButtonLoader';
import Toast from '../Utils/Toast';
import { RANGE_CLASS } from '../Utils/ModelClasses';
import Pagination from '../Components/Pagination';

const UserDetails = () => {
  const [userList, setUserList] = useState([]);
  const [date, setDate] = useState('');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [generalError, setGeneralError] = useState('');

  const [selectedData] = useState({});
  const rangeID = getItem('rangeId');

  // Pagination
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchUserList();
  }, [date, search]);

  const fetchUserList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        PROFICIENCY_EXAM_LIST_ENDPOINT +
          `?owner_class=${RANGE_CLASS}&owner_id=${rangeID}&exam_held_at=${date}&search=${search}&per_page=${itemsPerPage}&page=${currentPage}`
      );
      if (response.data.code === 200) {
        setTotalItems(response.data.data.total);
        setUserList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewProficiency = (proficiencyId) => {
    saveItem('examProficiency', proficiencyId);
  };

  // const handleUpdateStatus = (proficiency) => {
  //   setSelectedData(proficiency);
  // };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      setGeneralError('');
      setIsLoadingButton(true);
      const response = await axiosInstance.patch(
        PROFICIENCY_EXAM_UPDATE_ENDPOINT,
        {
          owner_class: RANGE_CLASS,
          owner_id: rangeID,
          id: selectedData?.id,
          status: 'CERTIFIED', // Required|String|CERTIFIED, PENDING, REJECTED
        }
      );
      if (response.data.code === 200) {
        const modalClose = document.getElementsByClassName('close-FPE-modal');
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        await fetchUserList();
        Toast.success(
          response.data.message
            ? response.data.message
            : 'Proficiency exam status updated. ',
          {
            autoClose: 2000,
          }
        );
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoadingButton(false);
    }
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body ">
                <div className="employees employ-set">
                  <h3 className="card-top-2 ">
                    FIREARM PROFICIENCY EXAMINATION
                  </h3>
                  <div className="all-date-selected">
                    <div className="date-input tickets">
                      <input
                        type="date"
                        className="form-control input-theme mb-0"
                        placeholder="date"
                        onChange={(e) => {
                          setDate(e.target.value);
                          setCurrentPage(1);
                        }}
                        value={date}
                      />
                    </div>
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setCurrentPage(1);
                        }}
                        value={search}
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-home"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="examination">
                              {userList?.length > 0 && (
                                <div className="table-responsive w-100">
                                  <table className="table table-time-gun-create mobile-responsive mobile table-w-d">
                                    <thead>
                                      <tr>
                                        <th className="d-block d-md-none"></th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Certification</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody className="table-time-gun-create__old-even mobile__old-even">
                                      {userList?.map((item, index) => (
                                        <tr key={index}>
                                          <td className="mob-tab-view td-left">
                                            <HexagonSize
                                              imageUser={
                                                item?.user?.profile_picture
                                                  ? item.user.profile_picture
                                                      .fullUrl
                                                  : '../assets/images/Headshot.jpg'
                                              }
                                            />
                                          </td>
                                          <td data-label="Name">
                                            <span className="user-n">
                                              <HexagonSize
                                                imageUser={
                                                  item?.user?.profile_picture
                                                    ? item?.user
                                                        ?.profile_picture
                                                        .fullUrl
                                                    : '../assets/images/Headshot.jpg'
                                                }
                                              />
                                              {item?.user?.name}
                                            </span>
                                            <span className="mob-tab-view">
                                              {item?.user?.name}
                                            </span>
                                          </td>
                                          <td data-label="Email">
                                            <p>{item?.user?.email}</p>
                                          </td>
                                          <td data-label="Certification">
                                            {item?.status === 'PENDING' && (
                                              <Link
                                                // data-bs-toggle="modal"
                                                // data-bs-target="#exampleModal"
                                                className="table-width-few-td"
                                                // onClick={() =>
                                                //   handleUpdateStatus(item)
                                                // }
                                              >
                                                <ButtonRemoveImage
                                                  Heading={item.status}
                                                  bgChange="bg-orange"
                                                />
                                              </Link>
                                            )}
                                            {item?.status === 'CERTIFIED' && (
                                              <Link className="table-width-few-td">
                                                <ButtonRemoveImage
                                                  Heading={item.status}
                                                  bgChange="success-bg-color success-bg-fill"
                                                  active="success-outline"
                                                />
                                              </Link>
                                            )}
                                          </td>
                                          <td data-label="Action">
                                            <Link
                                              onClick={() =>
                                                handleViewProficiency(item)
                                              }
                                              to="/ranges/cpe-validation-new"
                                              className="color-theme border-text"
                                            >
                                              Details
                                            </Link>
                                          </td>
                                        </tr>
                                      ))}
                                      {isLoading && (
                                        <SkeletonLoader
                                          type="table"
                                          rows={6}
                                          columns={6}
                                        />
                                      )}
                                      {/* <tr>
                                        <td className="d-block d-md-none td-left">
                                          <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                        </td>
                                        <td data-label="Name">
                                          <span className="user-n">
                                            <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                            Billy Squire
                                          </span>
                                          <span className="d-block d-md-none">
                                            Billy Squire
                                          </span>
                                        </td>
                                        <td data-label="CPE Type">Handgun</td>
                                        <td data-label="Certification">
                                          <Link
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            className="table-width-few-td"
                                          >
                                            <ButtonRemoveImage
                                              Heading="PENDING"
                                              bgChange="bg-orange"
                                            />
                                          </Link>
                                        </td>
                                        <td data-label="Admin Log">
                                          <Link
                                            to="/ranges/cpe-validation-new"
                                            className="color-theme border-text"
                                          >
                                            Details
                                          </Link>
                                        </td>
                                      </tr> */}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                              {userList?.length === 0 && !isLoading && (
                                <RangeNotFound
                                  title="Firearm Proficiency Examination"
                                  isInfo={true}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {userList?.length > 0 && (
                          <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={totalItems}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content modal-table">
            <div className="modal-header table-header">
              <div aria-label="Close" data-bs-dismiss="modal">
                <HexLine
                  hexBg="bg-bo-li-th"
                  hexOuterLine="bg-dark-bc"
                  imageUserTwo="../assets/icon/cross_icon.svg"
                />
              </div>
              <h5>ID VERIFICATION</h5>
            </div>
            <div className="modal-body identity-card">
              <div className="identity-card__body identity-card__border">
                {/* <div className="hex hex-3 m-auto">
                                <img src="../assets/images/seconde.jpeg" />
                            </div> */}
                <HexagonSize
                  hexOuterLine="bg-trans"
                  imageUser={
                    selectedData?.user?.profile_picture
                      ? selectedData?.user?.profile_picture?.fullUrl
                      : '../assets/images/seconde.jpeg'
                  }
                  hexBg="bg-black bg-org m-auto"
                />
                <h4>{selectedData?.user?.name}</h4>
              </div>
              <div className="identity-card__body">
                <HexLine
                  hexBg="bg-bo-li-th-w m-auto"
                  hexOuterLine="bg-dark-bc"
                  imageUserTwo={
                    selectedData?.entity_has_proficiency_type?.logo
                      ? selectedData?.entity_has_proficiency_type?.logo?.fullUrl
                      : '../assets/icon/id_verification.svg'
                  }
                  imgSizeTwo="today-size"
                />
                <h4>STATE ISSUED ID</h4>
              </div>
            </div>
            {generalError && (
              <p className="error text-center">{generalError}</p>
            )}
            <div className="modal-footer identity-button">
              <Link data-bs-dismiss="modal" className="close-FPE-modal">
                <ButtonRemoveImage
                  Heading="INCOMPLETE"
                  activeColor="white-color"
                  bgChange="bg-dark-gray"
                  active="orange-outline"
                />
              </Link>
              <ButtonLoader
                Heading="APPROVED"
                active="success-outline"
                bgChange="bg-dark-gray-success delete-w"
                isLoading={isLoadingButton}
                onClick={handleClick}
              >
                APPROVED
              </ButtonLoader>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
