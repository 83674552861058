import React, { useEffect, useState } from 'react';
import ButtonRemoveImage from './ButtonRemoveImage';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axiosInstance from '../Utils/axiosInstance';
import {
  RANGE_OPERATION_HOUR_DELETE_ENDPOINT,
  RANGE_OPERATION_HOUR_ENDPOINT,
} from '../Utils/Endpoints';
import Toast from '../Utils/Toast';
import ButtonLoader from './ButtonLoader';
import PropTypes from 'prop-types';
import { BAY_CLASS } from '../Utils/ModelClasses';
import Logger from '../Utils/Logger';

// Validation schema
const OperationHoursSchema = Yup.object().shape({
  day: Yup.string().required('Required'),
  startTime: Yup.string().required('Required'),
  endTime: Yup.string().required('Required'),
});

const OperationHours = ({ selectedBay, setSelectedBay }) => {
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [operationHourList, setOperationHourList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  useEffect(() => {
    if (selectedBay) {
      fetchOperationHour();
    }
  }, [selectedBay]);

  const validate = () => {
    try {
      const data = operationHourList[operationHourList.length - 1];
      const validateData = {
        day: data?.day,
        startTime: data?.startTime,
        endTime: data?.endTime,
      };
      OperationHoursSchema.validateSync(validateData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      setIsLoadingDelete(true);
      setGeneralError('');
      let endpoint = RANGE_OPERATION_HOUR_DELETE_ENDPOINT + id;
      let object = {};
      const response = await axiosInstance.deleteWithBody(endpoint, object);

      if (response.status === 204) {
        await fetchOperationHour();
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message || `Deletion failed.`);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      const response = await axiosInstance.post(RANGE_OPERATION_HOUR_ENDPOINT, {
        owner_class: BAY_CLASS,
        owner_id: selectedBay,
        day: operationHourList[operationHourList.length - 1].day,
        start: operationHourList[operationHourList.length - 1].startTime,
        end: operationHourList[operationHourList.length - 1].endTime,
        is_open: operationHourList[operationHourList.length - 1].isOpen,
      });
      if (response.data.code === 200) {
        fetchOperationHour();
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setGeneralError('');
    setErrors({});
    setOperationHourList([]);
    setSelectedBay('');
  };

  const fetchOperationHour = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        RANGE_OPERATION_HOUR_ENDPOINT +
          `/list?owner_class=${BAY_CLASS}&owner_id=${selectedBay}`
      );
      if (response.data.code === 200) {
        setOperationHourList(response.data.data);
        addNewRow();
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (id, field, value) => {
    setOperationHourList((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const addNewRow = () => {
    setOperationHourList((prevList) => [
      ...prevList,
      {
        id: 'hour_' + (prevList.length + 1),
        day: '',
        startTime: '',
        endTime: '',
        isOpen: false,
      },
    ]);
  };

  return (
    <>
      <div
        className="modal fade"
        id="OperationHours"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                Operation Hours
              </h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body modal-height">
                <div className="hours">
                  <div className="table-responsive w-100">
                    <table className="table table-time-gun-create mobile-responsive time-select">
                      <thead>
                        <tr>
                          <th>Day</th>
                          <th>Open Time</th>
                          <th>Close Time</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="table-time-gun-create__old-even time-o-c">
                        {operationHourList.map((item, index) => (
                          <>
                            {typeof item.id === 'number' ? (
                              <tr key={index}>
                                <td data-label="Day">
                                  <div className="select-input tickets">
                                    <p>{item.day}</p>
                                  </div>
                                </td>
                                <td data-label="Open Time">
                                  <div className="date-input tickets">
                                    <p>{item.startTime || item.start}</p>
                                  </div>
                                </td>
                                <td data-label="Close Time">
                                  <div className="date-input tickets">
                                    <p>{item.endTime || item.end}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={item.isOpen || item.is_open}
                                      disabled={typeof item.id === 'number'}
                                    />
                                    <label className="form-check-label">
                                      Open
                                    </label>
                                  </div>
                                </td>
                                <td data-label="Action">
                                  {typeof item.id === 'number' && (
                                    <button
                                      className="t-delete"
                                      type="button"
                                      disabled={isLoadingDelete}
                                      onClick={(e) => handleDelete(e, item.id)}
                                    >
                                      <img
                                        src="../assets/icon/delete.svg"
                                        alt=""
                                      />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ) : (
                              <tr key={index}>
                                <td data-label="Day">
                                  <div className="select-input tickets">
                                    <select
                                      className="form-select mb-0 form-select-white"
                                      onChange={(e) =>
                                        handleInputChange(
                                          item.id,
                                          'day',
                                          e.target.value
                                        )
                                      }
                                      value={item.day}
                                      required
                                      disabled={typeof item.id === 'number'}
                                    >
                                      <option value="">Select Day</option>
                                      <option value="MONDAY">MONDAY</option>
                                      <option value="TUESDAY">TUESDAY</option>
                                      <option value="WEDNESDAY">
                                        WEDNESDAY
                                      </option>
                                      <option value="THURSDAY">THURSDAY</option>
                                      <option value="FRIDAY">FRIDAY</option>
                                      <option value="SATURDAY">SATURDAY</option>
                                      <option value="SUNDAY">SUNDAY</option>
                                    </select>
                                    {errors.day && (
                                      <div className="error">{errors.day}</div>
                                    )}
                                  </div>
                                </td>
                                <td data-label="Open Time">
                                  <div className="date-input tickets">
                                    <input
                                      type="time"
                                      className="form-control mb-0"
                                      value={item.startTime || item.start}
                                      required
                                      onChange={(e) =>
                                        handleInputChange(
                                          item.id,
                                          'startTime',
                                          e.target.value
                                        )
                                      }
                                      step={1}
                                      disabled={typeof item.id === 'number'}
                                    />
                                    {errors.startTime && (
                                      <div className="error">
                                        {errors.startTime}
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td data-label="Close Time">
                                  <div className="date-input tickets">
                                    <input
                                      type="time"
                                      className="form-control mb-0"
                                      onChange={(e) =>
                                        handleInputChange(
                                          item.id,
                                          'endTime',
                                          e.target.value
                                        )
                                      }
                                      value={item.endTime || item.end}
                                      required
                                      step={1}
                                      disabled={typeof item.id === 'number'}
                                    />
                                    {errors.endTime && (
                                      <div className="error">
                                        {errors.endTime}
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleInputChange(
                                          item.id,
                                          'isOpen',
                                          e.target.checked
                                        )
                                      }
                                      checked={item.isOpen || item.is_open}
                                      disabled={typeof item.id === 'number'}
                                    />
                                    <label className="form-check-label">
                                      Open
                                    </label>
                                  </div>
                                </td>
                                <td data-label="Action"></td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {generalError && (
                <p className="error text-center">{generalError}</p>
              )}
              <div className="modal-footer border-0 justify-content-center">
                <div className="button-range-create mt-5">
                  <ButtonLoader
                    Heading="SAVE"
                    active="orange-outline"
                    bgChange="white-bg w-pd-add save-w"
                    type="submit"
                    isLoading={isLoading}
                  >
                    Save
                  </ButtonLoader>

                  <Link
                    to="#"
                    className="close-operation-hour-modal"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    <ButtonRemoveImage
                      Heading="Cancel"
                      active="gary-light-outline"
                      bgChange="gary-bg w-pd-add"
                    />
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

OperationHours.propTypes = {
  setSelectedBay: PropTypes.func,
  selectedBay: PropTypes.string,
};

export default OperationHours;
