import React from 'react';
import Label from '../Components/Label';
import { Link } from 'react-router-dom';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';

const FirearmRentalOptions = () => {
  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees">
                  <h3 className="card-top-2">Firearm Rental Options</h3>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      <div className="col-xl-8">
                        <p className="firearm-text">
                          NOTICE: Firearm rentals are based on available
                          inventory at the time.
                        </p>
                        <div className="select-text">
                          <h2>Handgun Selection</h2>
                        </div>
                        <div className="sec-gun-user-first">
                          <Label labelHeading="Action" />
                          <div className="select-input tickets">
                            <select id="gun" className="form-select">
                              <option selected>All Gun Select</option>
                              <option value="Handgun">HANDGUN</option>
                              <option value="ShotGun">ShotGun</option>
                              <option value="Rifle">Rifle</option>
                            </select>
                          </div>
                        </div>
                        <div className="sec-gun-user-first">
                          <Label labelHeading="Caliber" />
                          <div className="select-input tickets">
                            <select id="gun" className="form-select">
                              <option selected>All Gun Select</option>
                              <option value="Handgun">HANDGUN</option>
                              <option value="ShotGun">ShotGun</option>
                              <option value="Rifle">Rifle</option>
                            </select>
                          </div>
                        </div>
                        <Label
                          labelHeading="Available Inventory"
                          addCss="mt-3"
                        />
                        <div className="table-responsive w-100">
                          <table className="table table-time-gun-create mobile-responsive">
                            <thead>
                              <tr>
                                <th>SKU</th>
                                <th>Manufacturer</th>
                                <th>Model</th>
                                <th>S/N</th>
                                <th>Caliber</th>
                                <th>Rental Fee</th>
                              </tr>
                            </thead>
                            <tbody className="table-time-gun-create__old-even ">
                              <tr>
                                <td data-label="SUK">A-123</td>
                                <td data-label="Manufacturer">Sig Sauer</td>
                                <td data-label="Model">P-229</td>
                                <td data-label="S/N">123342892</td>
                                <td data-label="Caliber">9MM</td>
                                <td data-label="Rental Fee">$10.00</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="reservation-details mb-3">
                          <h4 className="r-d">Reservation Details</h4>
                          <div className="date-select-user">
                            <h4>
                              <img
                                src="../assets/icon/boking_calendar.svg"
                                alt=""
                              />
                              Selected Date :
                            </h4>
                            <p>September 6th 2024</p>
                          </div>
                          <div className="date-select-user">
                            <h4>
                              <img src="../assets/icon/time.svg" alt="" />
                              Start Time :
                            </h4>
                            <p>6:00pm</p>
                          </div>
                          <p className="par">Participants</p>
                          <div className="boj">
                            <p>Bob Johnson</p>
                            <img src="../assets/images/m.png" alt="" />
                          </div>
                          <div className="boj">
                            <p>Jill Junover</p>
                            <img src="../assets/images/g.png" alt="" />
                          </div>
                          <div className="date-select-user">
                            <h4>
                              <img src="../assets/icon/setting.svg" alt="" />
                              Firearm Selection
                            </h4>
                          </div>
                          <div className="listing-firearm">
                            <ul>
                              <li>Manufacturer</li>
                              <li>Model</li>
                              <li>S/N</li>
                            </ul>
                          </div>
                          <div className="date-select-user">
                            <h4>Sig Sauer 123342892</h4>
                            <p>P229</p>
                          </div>
                          <div className="date-select-user">
                            <h4>
                              <img src="../assets/icon/target.svg" alt="" />
                              Experience Add-Ons
                            </h4>
                          </div>
                        </div>
                        <Link to="#">
                          <ButtonRemoveImage
                            Heading="Next"
                            bgChange="bg-orange"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirearmRentalOptions;
