import React, { useEffect, useState } from 'react';
import ButtonAdd from '../Components/ButtonAdd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Label from '../Components/Label';
import RichTextEditor from '../Components/RichTextEditor';
import { getItem } from '../Utils/LocalStorage';
import Multiselect from 'multiselect-react-dropdown';
import Logger from '../Utils/Logger';
import {
  CATEGORY_LIST_ENDPOINT,
  COURSE_ENDPOINT,
  REMOVE_ENDPOINT,
} from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import ButtonLoader from '../Components/ButtonLoader';
import ImageUploadInput from '../Components/ImageUploadInput';
import Toast from '../Utils/Toast';
import * as Yup from 'yup';
import { COURSE_CLASS } from '../Utils/ModelClasses';
import { COURSE_BANNER_TYPE } from '../Utils/UploadType';
import { uploadFile } from '../Utils/upload';
import { convertTimeToUnix } from '../Utils/common';

// Validation schema
const CourseRangeSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  skillLevel: Yup.string().required('Required'),
  eventStartAt: Yup.string().required('Required'),
  eventEndAt: Yup.string().required('Required'),
});

const ClassCheckInForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [about, setAbout] = useState('');
  const [prerequisite, setPrerequisite] = useState('');
  const [durartion, setDurartion] = useState(0);
  const [skillLevel, setSkillLevel] = useState('');
  const [session, setSession] = useState(0);
  const [coin, setCoin] = useState(0);
  const [courseUrl, setCourseUrl] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [doorOpenTime, setDoorOpenTime] = useState('');
  const [eventStartAt, setEventStartAt] = useState('');
  const [eventEndAt, setEventEndAt] = useState('');
  const [hideEventAfterExpiration, setHideEventAfterExpiration] =
    useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [category, setCategory] = useState([]);
  const [courseID, setCourseID] = useState(null);
  const [imageID, setImageID] = useState(null);
  const [file, setFile] = useState(null);
  const rangeId = getItem('rangeId');
  const navigate = useNavigate();
  const location = useLocation();

  const selectedCourse = location.state && location.state.course;
  useEffect(() => {
    fetchCategoryList();
  }, []);

  const fetchCategoryList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        CATEGORY_LIST_ENDPOINT + '?type=COURSE'
      );
      if (response.data.code === 200) {
        setCategory(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCourse?.id) {
      setCourseID(selectedCourse?.id);
      setName(selectedCourse?.name);
      setSelectedCategory(
        selectedCourse?.entity_has_categories.map((item) => item.category)
      );
      setIsFeatured(selectedCourse?.is_featured);
      setIsActive(selectedCourse?.is_active);
      setDescription(
        selectedCourse?.description !== null ? selectedCourse?.description : ''
      );
      setAbout(selectedCourse?.about !== null ? selectedCourse?.about : '');
      setPrerequisite(selectedCourse?.prerequisite);
      setDurartion(selectedCourse?.durartion ? selectedCourse?.durartion : 0);
      setSession(selectedCourse?.session ? selectedCourse?.session : 0);
      setCoin(selectedCourse?.coins ? selectedCourse?.coins : 0);
      setSkillLevel(selectedCourse?.skill_level);
      setCourseUrl(selectedCourse?.course_url);
      // setFile(null);
      // setImageID(null);
      setTermsAndConditions(
        selectedCourse?.terms_and_conditions !== null
          ? selectedCourse?.terms_and_conditions
          : ''
      );
      // setDoorOpenTime(selectedCourse?.door_open_time);
      // setEventStartAt(selectedCourse?.event_start_at);
      // setEventEndAt(selectedCourse?.event_end_at);
      setHideEventAfterExpiration(selectedCourse?.hide_event_after_expiration);
    }
  }, [selectedCourse]);

  const handleDeleteImage = async (id) => {
    try {
      const response = await axiosInstance.delete(REMOVE_ENDPOINT + id);

      if (response.status === 204) {
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    }
  };

  const validate = () => {
    try {
      CourseRangeSchema.validateSync(
        {
          name,
          skillLevel,
          eventStartAt,
          eventEndAt,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      let responseCourseID;
      let res;
      if (!courseID) {
        const filteredArray = selectedCategory.map((item) => item.id);
        const response = await axiosInstance.post(COURSE_ENDPOINT + rangeId, {
          name: name,
          description: description, //nullable | string
          about: about, //nullable | string
          prerequisite: prerequisite, //nullable | string
          durartion: durartion, //nullable | integer
          skill_level: skillLevel, //required | string | ENTRY / INTERMEDIATE / EXPERT
          session: session, //nullable | integer
          coins: coin, //nullable | integer
          course_url: courseUrl, //nullable | string | URL
          terms_and_conditions: termsAndConditions, //nullable|string
          door_open_time: doorOpenTime
            ? convertTimeToUnix(doorOpenTime)
            : doorOpenTime, // nullable|integer|unix UTC timestamp
          event_start_at: convertTimeToUnix(eventStartAt), // required|integer|unix UTC timestamp
          event_end_at: convertTimeToUnix(eventEndAt), // required|integer|unix UTC timestamp
          hide_event_after_expiration: hideEventAfterExpiration ? 1 : 0, //boolean|0/1
          is_active: isActive ? 1 : 0,
          is_featured: isFeatured ? 1 : 0,
          categories: filteredArray,
        });
        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        responseCourseID = response.data.data.id;
        res = response.data.message;
        setCourseID(response.data.data.id);
      }

      if (file && !imageID) {
        try {
          const imageData = await uploadFile(
            COURSE_CLASS,
            responseCourseID ? responseCourseID : courseID,
            COURSE_BANNER_TYPE,
            file
          );
          setImageID(imageData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }

      Toast.success(res, {
        onClose: () => {
          navigate('/ranges/class');
          handleReset();
        },
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      let responseCourseID;
      let res;

      const filteredArray = selectedCategory.map((item) => item.id);
      const response = await axiosInstance.patch(
        COURSE_ENDPOINT + selectedCourse?.id + `/${rangeId}`,
        {
          name: name,
          description: description,
          about: about,
          prerequisite: prerequisite,
          durartion: durartion,
          skill_level: skillLevel,
          session: session,
          coins: coin,
          course_url: courseUrl,
          terms_and_conditions: termsAndConditions,
          door_open_time: doorOpenTime
            ? convertTimeToUnix(doorOpenTime)
            : doorOpenTime,
          event_start_at: convertTimeToUnix(eventStartAt),
          event_end_at: convertTimeToUnix(eventEndAt),
          hide_event_after_expiration: hideEventAfterExpiration ? 1 : 0,
          is_active: isActive ? 1 : 0,
          is_featured: isFeatured ? 1 : 0,
          categories: filteredArray,
        }
      );
      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      responseCourseID = response.data.data.id;
      res = response.data.message;

      if (file && !imageID) {
        try {
          const imageData = await uploadFile(
            COURSE_CLASS,
            responseCourseID ? responseCourseID : courseID,
            COURSE_BANNER_TYPE,
            file
          );
          setImageID(imageData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }

      Toast.success(res, {
        onClose: () => {
          navigate('/ranges/class');
          handleReset();
        },
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setFile(null);
    setImageID(null);
    setErrors({});
    setGeneralError('');
    setCourseID(null);
    setName('');
    setDescription('');
    setAbout('');
    setPrerequisite('');
    setDurartion(0);
    setSkillLevel('');
    setSession(0);
    setCoin(0);
    setCourseUrl('');
    setTermsAndConditions('');
    setDoorOpenTime('');
    setEventStartAt('');
    setEventEndAt('');
    setHideEventAfterExpiration(false);
    setIsActive(false);
    setIsFeatured(false);
    setSelectedCategory([]);
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="employees employ-set pt-4 pt-md-1">
                  <div className="back-listing-tab">
                    <Link to="/ranges/class" className="button-width">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2 h-set-2">
                      {selectedCourse?.id ? 'Update' : 'Add New '} Class
                    </h3>
                  </div>
                  <div className="all-date-selected mt-3  new-back-btn-add">
                    <Link to="/ranges/class" className="button-width b-p">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg"
                      />
                    </Link>
                  </div>
                </div>
                <form
                  className="triangle-top"
                  onSubmit={selectedCourse?.id ? handleUpdate : handleSubmit}
                >
                  <div className="body-text text-start">
                    <div className="row">
                      <div className="col-lg-4">
                        <Label labelHeading="Name" />
                        <div className="tickets">
                          <input
                            type="text"
                            className="form-control input-theme"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            placeholder=""
                          />
                          {errors.name && (
                            <div className="error">{errors.name}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <Label labelHeading="Prerequisite" />
                        <div className="tickets">
                          <input
                            type="text"
                            className="form-control input-theme"
                            onChange={(e) => setPrerequisite(e.target.value)}
                            value={prerequisite}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <Label labelHeading="Duration" />
                        <div className="tickets">
                          <input
                            type="number"
                            className="form-control input-theme"
                            onChange={(e) => setDurartion(e.target.value)}
                            value={durartion}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <Label labelHeading="About" />
                        <div className="tickets-2">
                          <RichTextEditor
                            value={about}
                            setEditorData={setAbout}
                            index="about"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <Label labelHeading="Description" />
                        <div className="tickets-2">
                          <RichTextEditor
                            value={description}
                            setEditorData={setDescription}
                            index="description"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Skill Level" />
                        <div className="tickets">
                          <select
                            id="inputState"
                            className="form-select"
                            onChange={(e) => setSkillLevel(e.target.value)}
                            value={skillLevel}
                          >
                            <option value="">Select.....</option>
                            <option value="ENTRY">ENTRY</option>
                            <option value="INTERMEDIATE">INTERMEDIATE</option>
                            <option value="EXPERT">EXPERT</option>
                          </select>
                          {errors.skillLevel && (
                            <div className="error">{errors.skillLevel}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <Label labelHeading="Session" />
                        <div className="tickets">
                          <input
                            type="number"
                            className="form-control input-theme"
                            onChange={(e) => setSession(e.target.value)}
                            value={session}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <Label labelHeading="Coins" />
                        <div className="tickets">
                          <input
                            type="number"
                            className="form-control input-theme"
                            onChange={(e) => setCoin(e.target.value)}
                            value={coin}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <Label labelHeading="Course URL" />
                        <div className="tickets">
                          <input
                            type="text"
                            className="form-control input-theme"
                            onChange={(e) => setCourseUrl(e.target.value)}
                            value={courseUrl}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Label labelHeading="Categories" />
                        <div className="tickets">
                          <Multiselect
                            isObject={true}
                            options={category}
                            selectedValues={selectedCategory}
                            showCheckbox
                            onSelect={(option) => setSelectedCategory(option)}
                            onRemove={(option) => setSelectedCategory(option)}
                            displayValue="name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <Label labelHeading="Door Open Time" />
                        <div className="tickets">
                          <input
                            type="datetime-local"
                            onChange={(e) => setDoorOpenTime(e.target.value)}
                            value={doorOpenTime}
                            className="form-control input-theme"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 ">
                        <Label labelHeading="Event Start" />
                        <div className="tickets">
                          <input
                            type="datetime-local"
                            className="form-control input-theme"
                            onChange={(e) => setEventStartAt(e.target.value)}
                            value={eventStartAt}
                          />
                          {errors.eventStartAt && (
                            <div className="error">{errors.eventStartAt}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 ">
                        <Label labelHeading="Event End" />
                        <div className="tickets">
                          <input
                            type="datetime-local"
                            className="form-control input-theme"
                            onChange={(e) => {
                              setEventEndAt(e.target.value);
                            }}
                            value={eventEndAt}
                          />
                          {errors.eventEndAt && (
                            <div className="error">{errors.eventEndAt}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                        <Label labelHeading="Terms and Conditions" />
                        <div className="tickets-2">
                          <RichTextEditor
                            value={termsAndConditions}
                            setEditorData={setTermsAndConditions}
                            index="termsAndConditions"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 my-3">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="Bike"
                          className="form-check-input"
                          checked={hideEventAfterExpiration}
                          onChange={(e) =>
                            setHideEventAfterExpiration(e.target.checked)
                          }
                        />
                        <label
                          htmlFor="vehicle1"
                          className="form-check-label ps-1"
                        >
                          Hide Event After Expiration
                        </label>
                      </div>
                      <div className="col-md-4 my-3">
                        <input
                          type="checkbox"
                          id="vehicle2"
                          name="vehicle2"
                          value="Bike"
                          className="form-check-input"
                          checked={isActive}
                          onChange={(e) => setIsActive(e.target.checked)}
                        />
                        <label
                          htmlFor="vehicle2"
                          className="form-check-label ps-1"
                        >
                          Active
                        </label>
                      </div>
                      <div className="col-md-4 my-3">
                        <input
                          type="checkbox"
                          id="vehicle3"
                          name="vehicle3"
                          value="Bike"
                          className="form-check-input"
                          checked={isFeatured}
                          onChange={(e) => setIsFeatured(e.target.checked)}
                        />
                        <label
                          htmlFor="vehicle3"
                          className="form-check-label ps-1"
                        >
                          Featured
                        </label>
                      </div>
                      <div className="col-md-12 mb-4 mt-2">
                        <div className="button-wrapper d-none">
                          <span className="label-input">
                            <strong>Product Image</strong>
                            Selected files are not actually uploaded.
                            Recommended Size 1920 X 700
                          </span>
                          <input
                            type="file"
                            name="upload"
                            id="upload"
                            className="upload-box"
                            placeholder="Upload File2"
                          />
                        </div>
                        <ImageUploadInput
                          setFile={setFile}
                          handleDeleteImage={handleDeleteImage}
                          // imageData={imageData}
                        />
                      </div>
                      {generalError && (
                        <p className="error text-center mt-3">{generalError}</p>
                      )}
                      <div className="set-button">
                        <ButtonLoader
                          type="submit"
                          bgChange={`white-bg w-pd-add`}
                          active="orange-outline"
                          isLoading={isLoading}
                        >
                          {selectedCourse?.id ? 'Update' : 'Save'}
                        </ButtonLoader>
                        <Link to="/ranges/class">
                          <ButtonRemoveImage
                            Heading="CANCEL"
                            active="gary-light-outline"
                            bgChange="gary-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassCheckInForm;
