import React, { useEffect, useState } from 'react';
import ButtonLoader from '../Components/ButtonLoader';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import { Link, useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import ButtonAdd from '../Components/ButtonAdd';
import Calendar from '../Components/Calendar';
import axiosInstance from '../Utils/axiosInstance';
import {
  BOOKING_ENDPOINT,
  GUEST_LIST_ENDPOINT,
  LANE_SLOT_ENDPOINT,
  LIST_BAY_ENDPOINT,
  LIST_BAY_LANES_ENDPOINT,
} from '../Utils/Endpoints';
import { getItem } from '../Utils/LocalStorage';
import Logger from '../Utils/Logger';
import Toast from '../Utils/Toast';
import SlotForm from '../Components/SlotForm';
import { LANE_CLASS } from '../Utils/ModelClasses';

const BookSlotUserAdd = () => {
  const [selected, setSelected] = useState('');
  const [selectedSlot, setSelectedSlot] = useState({});
  const [option, setOption] = useState([]);
  const [bayList, setBayList] = useState([]);
  const [laneList, setLaneList] = useState([]);
  const [, setGuestList] = useState([]);
  const [slotList, setSlotList] = useState(null);
  const currentDate = new Date();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [generalError, setGeneralError] = useState('');
  const [participantId, setParticipantId] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [bayId, setBayId] = useState('');
  const [laneId, setLaneId] = useState('');
  const rangeId = getItem('rangeId');
  const [selectedDate, setSelectedDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`
  );
  const navigate = useNavigate();

  useEffect(() => {
    fetchBayList();
    fetchGuestList();
  }, []);

  const fetchBayList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(LIST_BAY_ENDPOINT + rangeId);
      if (response.data.code === 200) {
        setBayList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGuestList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(GUEST_LIST_ENDPOINT + rangeId);
      if (response.data.code === 200) {
        setGuestList(response.data.data.data);
        if (response.data.data.data.length > 0) {
          setOption(
            response.data.data.data.map((item) => ({
              value: item.user_id,
              name: item.user.name + '  (Guest)',
            }))
          );
        }
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangeBay = async (id) => {
    try {
      setLaneId('');
      setLaneList([]);
      setSlotList(null);
      setSelectedSlot({});
      setSelected('');

      if (id === '') {
        setBayId('');
        return;
      }
      setIsLoading(true);

      setBayId(id);
      const response = await axiosInstance.get(LIST_BAY_LANES_ENDPOINT + id);
      if (response.data.code === 200) {
        setLaneList(response?.data?.data?.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangeLane = async (id) => {
    try {
      setSelectedSlot({});
      setSelected('');
      if (id === '') {
        setLaneId('');
        setSlotList(null);
        return;
      }
      setIsLoading(true);
      setLaneId(id);
      const response = await axiosInstance.get(
        LANE_SLOT_ENDPOINT + id + `?date=${selectedDate}`
      );
      if (response.data.code === 200) {
        setSlotList(response?.data?.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setBayId('');
    setGeneralError('');
    setSelected('');
    setSelectedSlot({});
    setLaneId('');
    setBayList([]);
    setLaneList([]);
    setSlotList(null);
    setSelectedUsers([]);
    setSelectedDate(
      `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`
    );
  };

  // Handler when options are selected
  const onSelect = (selectedList) => {
    setSelectedUsers(selectedList);
  };

  // Handler when options are removed
  const onRemove = (selectedList) => {
    setSelectedUsers(selectedList);
  };
  const handleSelectSlot = (slot) => {
    setSelected(slot.start);
    setSelectedSlot(slot);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setIsSubmitLoading(true);
      setGeneralError('');
      const userIds = selectedUsers?.map((user) => user.value) ?? [];
      const response = await axiosInstance.post(BOOKING_ENDPOINT, {
        items: [
          {
            owner_class: LANE_CLASS,
            owner_id: laneId,
            quantity: 1,
            details: {
              participant_ids: userIds[1]
                ? participantId
                  ? [userIds[1], participantId]
                  : [userIds[1]]
                : participantId
                  ? [participantId]
                  : [],
              from_slot: selectedSlot?.start_unix_timestamp,
              to_slot: selectedSlot?.end_unix_timestamp,
            },
          },
        ],
        customer_id: userIds[0],
        payment_method_id: 2,
      });

      if (response.data.code === 200) {
        navigate('/ranges/lane-check-list');
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
      setIsSubmitLoading(false);
    }
  };

  useEffect(() => {
    setLaneId('');
    setBayId('');
    setLaneList([]);
    setSlotList(null);
    setSelectedSlot({});
    setSelected('');
    setSelectedUsers([]);
  }, [selectedDate]);
  return (
    <>
      <div className="outer-border outer-over">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <div className="back-listing-tab">
                    <Link to="/ranges/lane-check-list" className="button-width">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2 h-set-mob">Book Slot</h3>
                  </div>

                  <div className="all-date-selected new-back-btn-add">
                    <Link
                      to="/ranges/lane-check-list"
                      className="button-width b-p"
                    >
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="">
                      <div className="">
                        <form onSubmit={handleSubmit}>
                          <div className="">
                            <Calendar
                              selectedDate={selectedDate}
                              setSelectedDate={setSelectedDate}
                            />

                            <div className="create-slots book-slots">
                              <div className="select-input tickets">
                                <select
                                  className="form-select mb-0"
                                  disabled={isLoading || bayList?.length === 0}
                                  onChange={(e) =>
                                    handleChangeBay(e.target.value)
                                  }
                                  value={bayId}
                                  required
                                >
                                  <option value="">Bay&lsquo;s</option>
                                  {bayList?.map((item, key) => (
                                    <option value={item.id} key={key}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="select-input tickets">
                                <select
                                  className="form-select mb-0"
                                  disabled={isLoading || laneList?.length === 0}
                                  onChange={(e) =>
                                    handleChangeLane(e.target.value)
                                  }
                                  required
                                >
                                  <option value="">Lane</option>
                                  {laneList?.map((item, key) => (
                                    <option value={item.id} key={key}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {laneId && (
                              <div className="time-select-bays-and-lane">
                                {slotList?.length > 0 && (
                                  <div className="custom-radio-group">
                                    {slotList.map((slot, index) => (
                                      <label
                                        key={index}
                                        className={`custom-radio  ${slot.booked ? ' disabled-slot-option ' : ''} ${selected === slot.start ? 'checked ' : ''}
                         `}
                                      >
                                        <input
                                          type="radio"
                                          name="slot"
                                          value={slot.start}
                                          checked={selected === slot.start}
                                          onChange={() =>
                                            handleSelectSlot(slot)
                                          }
                                          disabled={slot.booked}
                                        />
                                        <span className="custom-radio-label">
                                          {slot.start} - {slot.end}
                                        </span>
                                      </label>
                                    ))}
                                  </div>
                                )}
                                {slotList?.length === 0 && (
                                  <span className="text-danger fw-bolder d-flex justify-content-center">
                                    Slot Not Available.
                                  </span>
                                )}
                              </div>
                            )}
                            <div className="my-md-5">
                              <div className="row">
                                {Object.keys(selectedSlot).length > 0 && (
                                  <>
                                    <div className="col-md-6">
                                      <Multiselect
                                        isObject={true}
                                        options={option}
                                        selectedValues={selectedUsers}
                                        showCheckbox
                                        onSelect={onSelect}
                                        onRemove={onRemove}
                                        displayValue="name"
                                        selectionLimit={2}
                                      />
                                      <div className="user-count-select">
                                        {selectedUsers?.map((item, index) => (
                                          <div className="user-box" key={index}>
                                            <p>{item?.name}</p>
                                            <p className="cr-s-i">
                                              <img
                                                src="../assets/icon/cross-svgrepo-com.svg"
                                                alt=""
                                                onClick={() => {
                                                  setSelectedUsers(
                                                    selectedUsers.filter(
                                                      (currentItem) =>
                                                        currentItem !== item
                                                    )
                                                  );
                                                }}
                                              />
                                            </p>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="participants-button">
                                        <Link
                                          to="#"
                                          className="button-width"
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                        >
                                          <ButtonAdd
                                            images="../assets/icon/plaus_icon.svg"
                                            Heading="ADD Participants"
                                            active="orange-outline"
                                            bgChange="white-bg"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {selectedUsers?.length > 0 && (
                                  <div className="col-12 mt-4">
                                    <ul
                                      className="nav nav-tabs payment-card"
                                      id="myTab"
                                      role="tablist"
                                    >
                                      <li
                                        className="nav-item"
                                        role="presentation"
                                      >
                                        <div
                                          className="custom-action-button"
                                          id="home-tab"
                                          data-bs-toggle="tab"
                                          data-bs-target="#home"
                                          type="button"
                                          role="tab"
                                          aria-controls="home"
                                          aria-selected="true"
                                        >
                                          <button
                                            type="button"
                                            className="nav-link active bgChange"
                                          >
                                            <span>
                                              <img
                                                src="../assets/images/credit-card-min.svg"
                                                alt=""
                                              />
                                            </span>
                                            <span>TERMINAL</span>
                                          </button>
                                        </div>
                                      </li>
                                      <li
                                        className="nav-item"
                                        role="presentation"
                                      >
                                        <div
                                          className="custom-action-button"
                                          id="profile-tab"
                                          data-bs-toggle="tab"
                                          data-bs-target="#profile"
                                          type="button"
                                          role="tab"
                                          aria-controls="profile"
                                          aria-selected="false"
                                        >
                                          <button
                                            className="nav-link bgChange"
                                            type="button"
                                          >
                                            <span>
                                              <img
                                                src="../assets/images/stripe-com-min.svg"
                                                alt=""
                                              />
                                            </span>
                                            <span>CARDS</span>
                                          </button>
                                        </div>
                                      </li>
                                      <li
                                        className="nav-item"
                                        role="presentation"
                                      >
                                        <div
                                          className="custom-action-button"
                                          id="contact-tab"
                                          data-bs-toggle="tab"
                                          data-bs-target="#contact"
                                          type="button"
                                          role="tab"
                                          aria-controls="contact"
                                          aria-selected="false"
                                        >
                                          <button
                                            className="nav-link bgChange"
                                            type="button"
                                          >
                                            <span>
                                              <img
                                                src="../assets/images/cash-payment-min.svg"
                                                alt=""
                                              />{' '}
                                            </span>
                                            <span>CASH</span>
                                          </button>
                                        </div>
                                      </li>
                                    </ul>
                                    <div
                                      className="tab-content mt-4 card-payment-ab"
                                      id="myTabContent"
                                    >
                                      <div
                                        className="tab-pane fade show active"
                                        id="home"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                      >
                                        <div className="tickets">
                                          <input
                                            type="name"
                                            className="form-control input-theme"
                                            placeholder="Please Select location"
                                          />
                                        </div>
                                        <div className="tickets">
                                          <input
                                            type="name"
                                            className="form-control input-theme"
                                            placeholder="Please Select reader"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="tab-pane fade"
                                        id="profile"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                      >
                                        <div className="row">
                                          <div className="col-12">
                                            <div className="tickets">
                                              <input
                                                type="name"
                                                className="form-control input-theme"
                                                placeholder="1111-2222-3333-4444"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="tickets">
                                              <input
                                                type=""
                                                className="form-control input-theme"
                                                placeholder="MM/YY"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="tickets">
                                              <input
                                                type="name"
                                                className="form-control input-theme"
                                                placeholder="CVC"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="tab-pane fade"
                                        id="contact"
                                        role="tabpanel"
                                        aria-labelledby="contact-tab"
                                      >
                                        3
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {generalError && (
                            <p className="error text-center">{generalError}</p>
                          )}
                          {selectedUsers?.length > 0 && (
                            <div className="">
                              <div className="button-range-create mt-5">
                                <ButtonLoader
                                  Heading="Book"
                                  active="orange-outline"
                                  bgChange="white-bg w-pd-add save-w"
                                  type="submit"
                                  isLoading={isSubmitLoading}
                                >
                                  Book
                                </ButtonLoader>
                                <Link
                                  to="#"
                                  className="close-solcial-link-modal"
                                  data-bs-dismiss="modal"
                                  onClick={handleClose}
                                >
                                  <ButtonRemoveImage
                                    Heading="Cancel"
                                    active="gary-light-outline"
                                    bgChange="gary-bg w-pd-add"
                                  />
                                </Link>
                              </div>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlotForm setParticipantId={setParticipantId} />
    </>
  );
};

export default BookSlotUserAdd;
