import React, { useEffect, useState } from 'react';
import ButtonAdd from '../Components/ButtonAdd';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import HexagonSize from '../Components/HexagonSize';
import HexLine from '../Components/HexLine';
import BookSlot from '../Components/BookSlot';
import { Link } from 'react-router-dom';
import axiosInstance from '../Utils/axiosInstance';
import Logger from '../Utils/Logger';
import { BOOKING_LIST_ENDPOINT } from '../Utils/Endpoints';
import Pagination from '../Components/Pagination';
import RangeNotFound from '../Components/RangeNotFound';
import SkeletonLoader from '../Components/SkeletonLoader';
import moment from 'moment/moment';

const LaneCheckList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bookedList, setBookedList] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  // Pagination
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchBookedList();
  }, [search, currentPage]);

  const fetchBookedList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        BOOKING_LIST_ENDPOINT +
          `?order_item_type=LANE_BOOKING&page=${currentPage}&per_page=${itemsPerPage}&search=${search}`
      );
      if (response.data.code === 200) {
        setTotalItems(response.data.data.total);
        setBookedList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="outer-border ">
        <div className="container-fluid">
          <div className="row">
            {/* <h3 className="all-top-heading">LANE BOOKING CHECK-IN</h3> */}
            <div className="col-lg-12">
              <div className="card-body">
                {/* <h3 className="all-top-heading pb-3 pb-md-0">LANE BOOKING CHECK-IN</h3> */}
                <div className="employees employ-set">
                  <h3 className="card-top-2">LANE BOOKING CHECK-IN</h3>
                  <div className="all-date-selected">
                    <div className="date-input tickets">
                      <input
                        type="date"
                        className="form-control mb-0"
                        placeholder="Event Start Date"
                      />
                    </div>
                    <div className="select-input tickets">
                      <select className="form-select mb-0">
                        <option selected>Select Bay</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="select-input tickets">
                      <select className="form-select mb-0">
                        <option selected>Select lane</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                        id="search"
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setCurrentPage(1);
                        }}
                        value={search}
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text pt-0 create-padding">
                    <div className="listing-booking">
                      <ul className="lane-booking">
                        <li>
                          <img src="../assets/icon/user.svg" alt="iconfirst" />
                          <span>
                            <strong>2</strong>
                          </span>
                        </li>
                      </ul>
                      <ul className="lane-booking">
                        <li>
                          <img src="../assets/icon/bay.svg" alt="" />
                          <span>
                            <strong>BAY 1 | LANE 3666</strong>
                          </span>
                        </li>
                        <li>
                          <img src="../assets/icon/time.svg" alt="" />
                          <span>
                            <strong>4 PM CST</strong>
                          </span>
                        </li>
                      </ul>
                      <ul className="lane-booking">
                        <li>
                          <Link to="/ranges/book-slot-user-add">
                            <ButtonAdd
                              Heading="ADD"
                              images="../assets/icon/add_user.svg"
                              active="orange-outline"
                              bgChange="white-bg"
                            />
                          </Link>
                        </li>
                        <li>
                          <ButtonAdd
                            Heading="EDIT"
                            images="../assets/icon/edit.svg"
                            active="orange-outline"
                            bgChange="white-bg"
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-home"
                      >
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            <div className="table-responsive w-100">
                              {bookedList?.length === 0 && !isLoading && (
                                <RangeNotFound title="Booking" />
                              )}
                              {isLoading && <SkeletonLoader />}
                              {bookedList?.length > 0 && (
                                <table className="table table-time-gun-create mobile-responsive mobile">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Booking</th>
                                      <th>Booking Number</th>
                                      <th>Booking Details</th>
                                      <th>Account Class</th>
                                      <th>Account Status</th>
                                      <th>Check-In</th>
                                      <th>Admin Log</th>
                                    </tr>
                                  </thead>
                                  <tbody className="table-time-gun-create__old-even">
                                    {bookedList?.map((item, index) => (
                                      <tr key={index}>
                                        <td className="mob-tab-view td-left">
                                          <HexagonSize
                                            imageUser={
                                              item?.user?.profile_picture
                                                ? item?.user.profile_picture
                                                    .fullUrl
                                                : '../assets/images/Headshot.jpg'
                                            }
                                          />
                                        </td>
                                        <td data-label="Name">
                                          <span className="user-n-2 mob-tab-view-2">
                                            <HexagonSize
                                              imageUser={
                                                item?.user?.profile_picture
                                                  ? item?.user.profile_picture
                                                      ?.fullUrl
                                                  : '../assets/images/Headshot.jpg'
                                              }
                                            />
                                            {item?.user?.name}
                                          </span>
                                          <span className="mob-tab-view">
                                            {item?.user?.name}
                                          </span>
                                        </td>
                                        <td data-label="Booking">
                                          {/* <img
                                    src="../assets/icon/id_verification.svg"
                                    alt=""
                                  /> */}
                                          <p className="color-black-fw">
                                            ON-RANGE
                                          </p>
                                        </td>
                                        <td data-label="Booking Number">
                                          <p className="color-black-fw">
                                            {item?.order_number}
                                          </p>
                                        </td>
                                        <td data-label="Booking Details">
                                          <span className="color-black">
                                            RECEIPT
                                          </span>
                                          <img
                                            src="../assets/icon/download_icon.svg"
                                            alt="download_icon"
                                            style={{
                                              width: '9px',
                                              marginLeft: '5px',
                                            }}
                                          />
                                          <p>
                                            {moment(item.created_at).format(
                                              'MMMM D, YYYY h A z'
                                            )}
                                            {/* January 1, 2023 4 PM CST */}
                                          </p>
                                        </td>
                                        <td data-label="Account Class">
                                          <img
                                            src="../assets/icon/Icon-G.svg"
                                            alt=""
                                            style={{ width: '43px' }}
                                          />
                                        </td>
                                        <td
                                          data-label="Account Status"
                                          className="table-button-width"
                                        >
                                          <ButtonRemoveImage
                                            Heading="APPROVED"
                                            bgChange="success-bg-color success-bg-fill"
                                            active="success-outline"
                                          />
                                        </td>
                                        <td
                                          data-label="Check In"
                                          className="table-button-width"
                                        >
                                          <label
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            className="display"
                                            onClick={() =>
                                              setSelectedBooking(item)
                                            }
                                          >
                                            <ButtonRemoveImage
                                              Heading="PENDING"
                                              bgChange="bg-orange"
                                            />
                                          </label>
                                        </td>
                                        <td data-label="Admin Log">123</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                        {bookedList?.length > 0 && (
                          <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={totalItems}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                          />
                        )}
                      </div>
                      <div className="tab-pane fade" id="v-pills-profile">
                        2
                      </div>
                      <div className="tab-pane fade" id="v-pills-messages">
                        3
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content modal-table">
            <div className="modal-header table-header pb-0">
              <div aria-label="Close" data-bs-dismiss="modal">
                <HexLine
                  hexBg="bg-bo-li-th"
                  hexOuterLine="bg-dark-bc"
                  imageUserTwo="../assets/icon/cross_icon.svg"
                />
              </div>
              <h5 className="modal-title" id="exampleModalLabel">
                LANE CHECK-IN
              </h5>
            </div>
            <div className="modal-body py-0">
              <div className="user-check ">
                <div className="user-check__head">
                  <img
                    src="../assets/icon/Icon-G.svg"
                    alt=""
                    className="mt-4"
                    style={{ width: '50px' }}
                  />
                  <div className="user-check__id-list">
                    <div className="user-check__img-name user-check__line-mob">
                      <HexagonSize
                        hexOuterLine="bg-trans"
                        imageUser={
                          selectedBooking?.user?.profile_picture
                            ? selectedBooking?.user.profile_picture?.fullUrl
                            : '../assets/images/user-1.jpeg'
                        }
                        hexBg="bg-black bg-org "
                      />
                      <div className="user-check__title">
                        <h2>{selectedBooking?.user?.name}</h2>
                        <p>
                          {selectedBooking?.order_items &&
                            selectedBooking?.order_items[0]?.bookings[0]
                              .booking_participants.length}{' '}
                          Participants
                        </p>
                      </div>
                    </div>
                    <div className="user-check__name ">
                      <div className="user-check__month">
                        <div className="user-check__out-line-right">
                          <div className="pro-hex ">
                            <div className="bg-gray"></div>
                          </div>
                        </div>
                        <div className="user-check__cst">
                          <h4>Check-In</h4>
                          <p>January 1, 2023 10:33 AM CST</p>
                          <div className="d-none d-md-block">
                            <ButtonRemoveImage
                              Heading="CHECK-IN"
                              active="success-outline"
                              bgChange="bg-dark-gray-success"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="user-check__month">
                        <div className="pro-hex">
                          <div className="bg-gray"></div>
                        </div>
                        <div className="user-check__cst">
                          <h4>Check-Out</h4>
                          <p>January 1, 2023 10:33 AM CST</p>
                          <div className="d-block d-md-none">
                            <ButtonRemoveImage
                              Heading="CHECK-IN"
                              active="success-outline"
                              bgChange="bg-dark-gray-success"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookSlot isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default LaneCheckList;
