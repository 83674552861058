import React, { useEffect, useState } from 'react';
import ButtonLoader from './ButtonLoader';
import ButtonRemoveImage from './ButtonRemoveImage';
import { Link } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import ButtonAdd from './ButtonAdd';
import Label from './Label';
import Calendar from './Calendar';
import axiosInstance from '../Utils/axiosInstance';
import {
  BOOKING_ENDPOINT,
  GUEST_LIST_ENDPOINT,
  LANE_SLOT_ENDPOINT,
  LIST_BAY_ENDPOINT,
  LIST_BAY_LANES_ENDPOINT,
  PARTICIPANT_ENDPOINT,
} from '../Utils/Endpoints';
import { getItem } from '../Utils/LocalStorage';
import Logger from '../Utils/Logger';
import PropTypes from 'prop-types';
import Toast from '../Utils/Toast';
import { emailRegExp, phoneRegExp } from '../Utils/common';
import * as Yup from 'yup';
import { LANE_CLASS } from '../Utils/ModelClasses';

// Validation schema
const ParticipantSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid email address')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  phoneNumber: Yup.string()
    .required('Required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
  dob: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
});

const BookSlot = ({ isModalOpen, setIsModalOpen }) => {
  const [selected, setSelected] = useState('');
  const [selectedSlot, setSelectedSlot] = useState({});
  const [option, setOption] = useState([]);
  const [status, setStatus] = useState(false);
  const [bayList, setBayList] = useState([]);
  const [laneList, setLaneList] = useState([]);
  const [, setGuestList] = useState([]);
  const [slotList, setSlotList] = useState([]);
  const currentDate = new Date();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [generalError, setGeneralError] = useState('');
  const [participantId, setParticipantId] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [, setBayId] = useState('');
  const [laneId, setLaneId] = useState('');
  const rangeId = getItem('rangeId');
  const [selectedDate, setSelectedDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`
  );

  //Participants
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isModalOpen) {
      fetchBayList();
      fetchGuestList();
    }
  }, [isModalOpen]);

  const validate = () => {
    try {
      ParticipantSchema.validateSync(
        {
          firstName,
          lastName,
          dob,
          email,
          phoneNumber,
          gender,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const fetchBayList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(LIST_BAY_ENDPOINT + rangeId);
      if (response.data.code === 200) {
        setBayList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGuestList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(GUEST_LIST_ENDPOINT + rangeId);
      if (response.data.code === 200) {
        setGuestList(response.data.data.data);
        if (response.data.data.data.length > 0) {
          setOption(
            response.data.data.data.map((item) => ({
              value: item.user_id,
              name: item.user.name + '  (Guest)',
            }))
          );
        }
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangeBay = async (id) => {
    try {
      setLaneId('');
      setLaneList([]);
      setSlotList([]);
      if (id === '') {
        setBayId('');
        return;
      }
      setIsLoading(true);

      setBayId(id);
      const response = await axiosInstance.get(LIST_BAY_LANES_ENDPOINT + id);
      if (response.data.code === 200) {
        setLaneList(response?.data?.data?.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangeLane = async (id) => {
    try {
      if (id === '') {
        setLaneId('');
        setSlotList([]);
        return;
      }
      setIsLoading(true);
      setLaneId(id);
      const response = await axiosInstance.get(
        LANE_SLOT_ENDPOINT + id + `?date=${selectedDate}`
      );
      if (response.data.code === 200) {
        setSlotList(response?.data?.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setBayId('');
    setGeneralError('');
    setLaneId('');
    setBayList([]);
    setLaneList([]);
    setSlotList([]);
    setSelectedUsers([]);
    setIsModalOpen(false);
    setStatus(false);
    setEmail('');
    setPhoneNumber('');
    setFirstName('');
    setLastName('');
    setDob('');
    setSelected('');
    setSelectedSlot({});
    setGender('');
    setSelectedDate(
      `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`
    );
  };

  // Handler when options are selected
  const onSelect = (selectedList) => {
    setSelectedUsers(selectedList);
  };

  // Handler when options are removed
  const onRemove = (selectedList) => {
    setSelectedUsers(selectedList);
  };

  const handleSelectSlot = (slot) => {
    setSelected(slot.start);
    setSelectedSlot(slot);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setIsSubmitLoading(true);
      setGeneralError('');
      const userIds = selectedUsers?.map((user) => user.value) ?? [];
      const response = await axiosInstance.post(BOOKING_ENDPOINT, {
        items: [
          {
            owner_class: LANE_CLASS,
            owner_id: laneId,
            quantity: 1,
            details: {
              participant_ids: [participantId ? participantId : userIds[1]],
              from_slot: selectedSlot?.start_unix_timestamp,
              to_slot: selectedSlot?.end_unix_timestamp,
            },
          },
        ],
        customer_id: userIds[0],
        payment_method_id: 2,
      });

      if (response.data.code === 200) {
        const modalClose = document.getElementsByClassName(
          'close-booking-modal'
        );
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
      setIsSubmitLoading(false);
    }
  };

  const handleParticipant = async (e) => {
    e.preventDefault();
    try {
      if (!validate()) return;
      setIsLoading(true);
      setIsSubmitLoading(true);
      setGeneralError('');
      const response = await axiosInstance.post(PARTICIPANT_ENDPOINT, {
        email: email,
        phone_number: phoneNumber,
        first_name: firstName,
        last_name: lastName,
        dob: dob,
        gender: gender,
      });
      if (response.data.code === 200) {
        setParticipantId(response?.data?.data?.id);
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="bookSlot"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                Book Slot
              </h5>
              <a
                className="close-firearm-2 close-booking-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <img src="../assets/icon/cross-svgrepo-com.svg" alt="" />
              </a>
            </div>
            <form
              onSubmit={
                status && participantId === ''
                  ? handleParticipant
                  : handleSubmit
              }
            >
              <div className="modal-body modal-height">
                <Calendar
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
                <div className="create-slots book-slots">
                  <div className="select-input tickets">
                    <select
                      className="form-select mb-0"
                      disabled={isLoading || bayList?.length === 0}
                      onChange={(e) => handleChangeBay(e.target.value)}
                      required
                    >
                      <option value="">Bay&lsquo;s</option>
                      {bayList?.map((item, key) => (
                        <option value={item.id} key={key}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="select-input tickets">
                    <select
                      className="form-select mb-0"
                      disabled={isLoading || laneList?.length === 0}
                      onChange={(e) => handleChangeLane(e.target.value)}
                      required
                    >
                      <option value="">Lane</option>
                      {laneList?.map((item, key) => (
                        <option value={item.id} key={key}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {laneId && (
                  <div className="time-select-bays-and-lane">
                    {slotList?.length > 0 && (
                      <div className="custom-radio-group">
                        {slotList.map((slot, index) => (
                          <label
                            key={index}
                            className={`custom-radio  ${slot.booked ? ' disabled-slot-option ' : ''} ${selected === slot.start ? 'checked ' : ''}
                         `}
                          >
                            <input
                              type="radio"
                              name="slot"
                              value={slot.start}
                              checked={selected === slot.start}
                              onChange={() => handleSelectSlot(slot)}
                              disabled={slot.booked}
                            />
                            <span className="custom-radio-label">
                              {slot.start} - {slot.end}
                            </span>
                          </label>
                        ))}
                      </div>
                    )}
                    {slotList?.length === 0 && (
                      <span className="text-danger fw-bolder d-flex justify-content-center">
                        Slot Not Available.
                      </span>
                    )}
                  </div>
                )}

                <div className="my-4">
                  <div className="row">
                    <div className="col-md-6">
                      <Multiselect
                        isObject={true}
                        options={option}
                        selectedValues={selectedUsers}
                        showCheckbox
                        onSelect={onSelect}
                        onRemove={onRemove}
                        displayValue="name"
                        selectionLimit={2}
                      />
                      <div className="user-count-select">
                        {selectedUsers?.map((item, index) => (
                          <div className="user-box" key={index}>
                            <p>{item?.name}</p>
                            <p className="cr-s-i">
                              <img
                                src="../assets/icon/cross-svgrepo-com.svg"
                                alt=""
                                onClick={() => {
                                  setSelectedUsers(
                                    selectedUsers.filter(
                                      (currentItem) => currentItem !== item
                                    )
                                  );
                                }}
                              />
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <Link
                          to="#"
                          className="button-width d-flex justify-content-end"
                          onClick={() => setStatus(!status)}
                        >
                          <ButtonAdd
                            images="../assets/icon/plaus_icon.svg"
                            Heading="ADD Participants"
                            active="orange-outline"
                            bgChange="white-bg"
                          />
                        </Link>
                        {status ? (
                          <form action="" className="mt-3">
                            <Label labelHeading="First Name" />
                            <div className="tickets">
                              <input
                                type="text"
                                className="form-control input-theme"
                                placeholder=""
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                              {errors.firstName && (
                                <div className="error">{errors.firstName}</div>
                              )}
                            </div>
                            <Label labelHeading="Last Name" />
                            <div className="tickets">
                              <input
                                type="text"
                                className="form-control input-theme"
                                placeholder=""
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                              {errors.lastName && (
                                <div className="error">{errors.lastName}</div>
                              )}
                            </div>
                            <Label labelHeading="Email" />
                            <div className="tickets">
                              <input
                                type="email"
                                className="form-control input-theme"
                                placeholder=""
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              {errors.email && (
                                <div className="error">{errors.email}</div>
                              )}
                            </div>
                            <Label labelHeading="Dob" />
                            <div className="tickets">
                              <input
                                type="date"
                                className="form-control input-theme"
                                onChange={(e) => setDob(e.target.value)}
                                value={dob}
                              />
                              {errors.dob && (
                                <div className="error">{errors.dob}</div>
                              )}
                            </div>
                            <Label labelHeading="Phone" />
                            <div className="tickets">
                              <input
                                type="tell"
                                className="form-control input-theme"
                                placeholder=""
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                              />
                              {errors.phoneNumber && (
                                <div className="error">
                                  {errors.phoneNumber}
                                </div>
                              )}
                              <Label labelHeading="Gender" />
                              <div className="select-input tickets">
                                <select
                                  className="form-select mb-0"
                                  value={gender}
                                  onChange={(e) => setGender(e.target.value)}
                                >
                                  <option selected>Gender</option>
                                  <option value="MALE">Man</option>
                                  <option value="FEMALE">Woman</option>
                                  <option value="OTHER">Other</option>
                                </select>
                                {errors.gender && (
                                  <div className="error">{errors.gender}</div>
                                )}
                              </div>
                            </div>
                          </form>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {generalError && (
                <p className="error text-center">{generalError}</p>
              )}
              <div className="modal-footer border-0 justify-content-center">
                <div className="button-range-create mt-5">
                  <ButtonLoader
                    Heading="SAVE"
                    active="orange-outline"
                    bgChange={
                      'white-bg ' +
                      (status && participantId === '' ? ' w-pd-add' : 'save-w')
                    }
                    type="submit"
                    isLoading={isSubmitLoading}
                  >
                    {status && participantId === ''
                      ? 'Save Participant'
                      : 'save'}
                  </ButtonLoader>

                  <Link
                    to="#"
                    className="close-solcial-link-modal"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    <ButtonRemoveImage
                      Heading="Cancel"
                      active="gary-light-outline"
                      bgChange="gary-bg w-pd-add"
                    />
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
BookSlot.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
};

export default BookSlot;
