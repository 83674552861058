import React, { useEffect, useState } from 'react';
import HexagonSize from '../Components/HexagonSize';
import ButtonAdd from '../Components/ButtonAdd';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import { getItem } from '../Utils/LocalStorage';
import { COURSE_LIST_ENDPOINT } from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import Logger from '../Utils/Logger';
import SkeletonLoader from '../Components/SkeletonLoader';
import RangeNotFound from '../Components/RangeNotFound';
import Pagination from '../Components/Pagination';
import { Link } from 'react-router-dom';

const Class = () => {
  const [search, setSearch] = useState('');
  const [courseList, setCourseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const rangeId = getItem('rangeId');

  // Pagination
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchCourseList();
  }, [search, currentPage]);

  const fetchCourseList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        COURSE_LIST_ENDPOINT +
          `${rangeId}?per_page=${itemsPerPage}&page=${currentPage}&search=${search}`
      );
      if (response.data.code === 200) {
        setTotalItems(response.data.data.total);
        setCourseList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="outer-border ">
        <div className="container-fluid">
          <div className="row">
            {/* <h3 className="all-top-heading">CLASS CHECK-IN</h3> */}
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">CLass CHECK-IN</h3>
                  <div className="all-date-selected">
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setCurrentPage(1);
                        }}
                        value={search}
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                    {/* <div className="date-input tickets">
                      <input
                        type="date"
                        className="form-control mb-0"
                        placeholder="Event Start Date"
                      />
                    </div>
                    <div className="select-input tickets">
                      <select className="form-select mb-0">
                        <option selected>Select Bay</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="select-input tickets">
                      <select className="form-select mb-0">
                        <option selected>Select Range</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div> */}
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text pt-0">
                    <div className="listing-booking">
                      <ul className="lane-booking mb-0">
                        <li>
                          <img src="../assets/icon/user.svg" alt="iconfirst" />
                          <span>
                            <strong>2</strong>
                          </span>
                        </li>
                      </ul>
                      <ul className="lane-booking">
                        <li>
                          <img src="../assets/icon/bay.svg" alt="" />
                          <span>
                            <strong>BAY 1 | LANE 3</strong>
                          </span>
                        </li>
                        <li>
                          <img src="../assets/icon/time.svg" alt="" />
                          <span>
                            <strong>4 PM CST</strong>
                          </span>
                        </li>
                      </ul>
                      <ul className="lane-booking">
                        <li>
                          <Link to="/ranges/class-check-in-form">
                            <ButtonAdd
                              Heading="ADD Class"
                              images="../assets/icon/add_user.svg"
                              active="orange-outline"
                              bgChange="white-bg "
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content pt-3">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-home"
                      >
                        {courseList?.length === 0 && !isLoading && (
                          <RangeNotFound title="Courses" />
                        )}
                        {isLoading && <SkeletonLoader />}
                        {courseList?.length > 0 && !isLoading && (
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div className="table-responsive">
                                <table className="table table-time-gun-create mobile-responsive mobile">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Booking Details</th>
                                      <th>Account Class</th>
                                      <th>Account Status</th>
                                      <th>Check-In</th>
                                      <th>ACTIONS</th>
                                    </tr>
                                  </thead>
                                  <tbody className="table-time-gun-create__old-even">
                                    {courseList?.map((item, index) => (
                                      <tr key={index}>
                                        <td
                                          className="mob-tab-view td-left"
                                          key={index}
                                        >
                                          <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                        </td>
                                        <td
                                          data-label="Name"
                                          style={{ width: '200px' }}
                                        >
                                          <span className="user-n-2 mob-tab-view-2">
                                            <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                            {item?.name}
                                          </span>
                                          <span className="mob-tab-view">
                                            {item?.name}
                                          </span>
                                        </td>
                                        <td data-label="Booking Details">
                                          <span className="color-black">
                                            RECEIPT
                                          </span>
                                          <img
                                            src="../assets/icon/download_icon.svg"
                                            alt="download_icon"
                                            style={{
                                              width: '9px',
                                              marginLeft: '5px',
                                            }}
                                          />
                                          <p>January 1, 2023 4 PM CST</p>
                                        </td>
                                        <td data-label="Account Class">
                                          <img
                                            src="../assets/icon/Icon-M.svg"
                                            alt=""
                                            style={{ width: '43px' }}
                                          />
                                        </td>
                                        <td
                                          data-label="Account Status"
                                          className="table-button-width"
                                        >
                                          <ButtonRemoveImage
                                            Heading="APPROVED"
                                            bgChange="success-bg-color success-bg-fill"
                                            active="success-outline"
                                          />
                                        </td>
                                        <td
                                          data-label="Check In"
                                          className="table-button-width"
                                        >
                                          <ButtonRemoveImage
                                            Heading="PENDING"
                                            bgChange="bg-orange"
                                          />
                                        </td>
                                        <td className="td-left">
                                          <div className="edit-user-id">
                                            <Link
                                              to="/ranges/class-check-in-form"
                                              state={{
                                                course: item,
                                              }}
                                              className="or-col border-theme"
                                            >
                                              <img
                                                src="../assets/icon/edit.svg"
                                                alt=""
                                              />
                                            </Link>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <Pagination
                              itemsPerPage={itemsPerPage}
                              totalItems={totalItems}
                              currentPage={currentPage}
                              onPageChange={handlePageChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Class;
