import React from 'react';
import { Link } from 'react-router-dom';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import HexagonSize from '../Components/HexagonSize';
import ButtonAdd from '../Components/ButtonAdd';

const OrderDetails = () => {
  return (
    <>
      <div className="outer-border outer-over-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                {/* <div className="employees">
                  <h3 className="card-top-2">Order Details</h3>
                </div> */}
                <div className="employees employ-set pt-4 pt-md-1">
                  <div className="back-listing-tab">
                    <Link to="/ranges/experience" className="button-width">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2 h-set-2">Order Details</h3>
                  </div>
                  <div className="all-date-selected mt-3  new-back-btn-add">
                    <Link to="/ranges/experience" className="button-width b-p">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      <div className="col-xl-8">
                        <div className="exp-add">
                          <div className=" us-one">
                            <p className="n_m">
                              <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                              Ankle boots
                            </p>
                            <p className="m-b-w">
                              <img src="../assets/icon/phone-call.svg" alt="" />
                              7088706543
                            </p>
                            <p className="m-b-w gift">
                              <img
                                src="../assets/icon/gift-svgrepo.svg"
                                alt=""
                              />
                              15% Discount offer
                            </p>
                            <p className="m-b-w">
                              <img src="../assets/icon/user.svg" alt="" />
                              Regular
                            </p>
                          </div>
                          <div className="examination order-top-th">
                            <div className="table-responsive w-100">
                              <table className="table table-time-gun-create mobile-responsive mobile">
                                <thead>
                                  <tr>
                                    <th>SKU</th>
                                    <th>Manufacturer</th>
                                    <th>Model</th>
                                    <th>S/N</th>
                                    <th>Caliber</th>
                                    <th>Rental Fee</th>
                                  </tr>
                                </thead>
                                <tbody className="table-time-gun-create__old-even mobile__old-even">
                                  <tr>
                                    <td data-label="SKU">
                                      <p>A-123</p>
                                    </td>
                                    <td data-label="Manufacturer">
                                      <p>Sig Sauer</p>
                                    </td>
                                    <td data-label="Model">
                                      <p>P229</p>
                                    </td>
                                    <td data-label="S/N">
                                      <p>123342892</p>
                                    </td>
                                    <td data-label="Caliber">
                                      <p>9MM</p>
                                    </td>
                                    <td data-label="Rental Fee">
                                      <p>$10.00</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="discount-card mt-4">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="discount-payment">
                                  <p className="d-c">
                                    <span className="d-c-g">
                                      <img
                                        src="../assets/icon/gift-svgrepo.svg"
                                        alt=""
                                      />
                                    </span>
                                    Discount coupon
                                  </p>
                                  <p className="offered-text">
                                    Here apply the offered discount coupons or
                                    customers provided coupons for special
                                    discount on current carve
                                  </p>
                                  <div className="cou-pon">
                                    <div className="tickets">
                                      <input
                                        type="name"
                                        className="form-control input-theme"
                                        placeholder="Enter coupon code here ex: 200FFDIS"
                                      />
                                    </div>
                                    <Link to="#">
                                      <ButtonRemoveImage
                                        Heading="Apply"
                                        bgChange="bg-orange"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="sub-total">
                                  <ul>
                                    <li className="first-total">
                                      <p>Subtotal</p>
                                      <p>$40.92</p>
                                    </li>
                                    <li>
                                      <p>Service charges</p>
                                      <p>+$4.88</p>
                                    </li>
                                    <li>
                                      <p>Restaurant tax </p>
                                      <p>+$12.67</p>
                                    </li>
                                    <li>
                                      <p>Special discount </p>
                                      <p>+$12.67</p>
                                    </li>
                                    <li className="last-footer">
                                      <p>Total</p>
                                      <p>$35.04</p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="reservation-details mb-3 pay-mode">
                          <h4 className="r-d">Select payment mode</h4>
                          <p className="s-p-t">
                            Select a payment method that helps our customers to
                            feel seamless experience during checkout
                          </p>
                          <div className="suman">
                            <div className="select_time_wrapper">
                              <label className="rounded-0 text-white">
                                <input
                                  type="radio"
                                  name="toggle"
                                  className="d-none"
                                />
                                <span className="text-center d-block py-3">
                                  <img
                                    src="../assets/icon/atm-card.svg"
                                    alt=""
                                    className="card-pay"
                                  />
                                  Pay using card
                                </span>
                              </label>
                              <label className="rounded-0 text-white">
                                <input
                                  type="radio"
                                  name="toggle"
                                  className="d-none"
                                />
                                <span className="text-center d-block py-3">
                                  <img
                                    src="../assets/icon/cash-or-credit.svg"
                                    alt=""
                                    className="card-pay"
                                  />
                                  Pay on cash
                                </span>
                              </label>
                              <label className="rounded-0 text-white">
                                <input
                                  type="radio"
                                  name="toggle"
                                  className="d-none"
                                />
                                <span className="text-center d-block py-3">
                                  <img
                                    src="../assets/icon/barcode.svg"
                                    alt=""
                                    className="card-pay"
                                  />
                                  Pay using Terminal
                                </span>
                              </label>
                            </div>
                          </div>
                          <Link to="#" className="px-2 d-block">
                            <ButtonRemoveImage
                              Heading="Confirm payment"
                              bgChange="bg-orange"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
