import React from 'react';
import RangeForm from './RangeForm';
import PropTypes from 'prop-types';

const AssociateRangesModal = ({ fetchAssociateRange }) => {
  return (
    <>
      <div
        className="modal fade"
        id="associateRanges"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                ASSOCIATE RANGES
              </h5>
            </div>
            <RangeForm
              type="associate_range"
              fetchAssociateRange={fetchAssociateRange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

AssociateRangesModal.propTypes = {
  fetchAssociateRange: PropTypes.func,
};

export default AssociateRangesModal;
