import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axiosInstance from '../Utils/axiosInstance';
import { LOGIN_ENDPOINT } from '../Utils/Endpoints';
import {
  clearItem,
  getItem,
  saveItem,
  saveObject,
  setLoginUser,
} from '../Utils/LocalStorage';
import { fetchRange } from '../Redux/features/ranges/rangeSlice';
import { useDispatch } from 'react-redux';

// Validation schema
const SignInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const storedEmail = getItem('email');
    const storedPassword = getItem('password');
    if (storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
    }
  }, []);

  const validate = () => {
    try {
      SignInSchema.validateSync({ email, password }, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const response = await axiosInstance.post(LOGIN_ENDPOINT, {
        email,
        password,
      });
      if (response.data.code === 200) {
        const { token, user } = response.data.data;
        saveObject('token', token);
        setLoginUser(user);
        await dispatch(fetchRange());

        if (isChecked) {
          saveItem('email', email);
          saveItem('password', password);
        } else {
          clearItem('email');
          clearItem('password');
        }

        navigate('/dashboard');
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message || 'Login failed');
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="sign-in">
      <div className="text-center">
        <img src="../assets/images/webside.webp" alt="" className="sign-logo" />
      </div>
      <h3>Sign in</h3>
      {generalError && <p className="error">{generalError}</p>}
      <div className="triangle-card sign-in-card">
        <form onSubmit={handleSubmit} className="sign-form">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Email address*
          </label>
          <div className="input-search-items tickets">
            <input
              type="text"
              className="form-control input-theme mb-0"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>
          <label htmlFor="exampleInputEmail1" className="form-label">
            Password*
          </label>
          <div className="password input-search-items tickets">
            <input
              type={showPassword ? 'text' : 'password'}
              className="form-control input-theme mb-0"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              className="input-group-text"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <img src="../assets/icon/eye-password-hide.svg" alt="" />
              ) : (
                <img src="../assets/icon/eye-password-show.svg" alt="" />
              )}
            </span>
            {errors.password && <div className="error">{errors.password}</div>}
          </div>
          <div className="mt-3 d-block text-left">
            <input
              type="checkbox"
              className="form-check-input"
              id="Check1"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label className="form-label-2 ps-2 pt-1" htmlFor="Check1">
              Remember me
            </label>
          </div>
          <div className="custom-action-button mt-3">
            <button type="submit" className="white-bg py-2">
              Sign In
            </button>
          </div>
        </form>
      </div>
      <Link to="/reset-password" className="pass-far">
        Reset Password
      </Link>
    </div>
  );
};

export default SignIn;
