import React from 'react';
import UserProcessing from '../Components/UserProcessing';

const Today = () => {
  return (
    <>
      <div className="outer-border outer-overflow-show">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="card-body">
                <h3>Classes</h3>
                <div className="triangle-top">
                  <div className="body-text body-listing body-listing scrolling">
                    <ul>
                      <li>
                        <h4>
                          Handgun 101 <span>1PM-2PM CST</span>
                        </h4>
                        <p>
                          Stoddard’s - Midtown <span>Mitch O.</span>
                        </p>
                      </li>
                      <li>
                        <h4>
                          Rifle 101 <span>2PM-3PM CST</span>
                        </h4>
                        <p>
                          Stoddard’s - Midtown <span>Mitch O.</span>
                        </p>
                      </li>
                      <li>
                        <h4>
                          Handgun 101 <span>1PM-2PM CST</span>
                        </h4>
                        <p>
                          Stoddard’s - Midtown <span>Mitch O.</span>
                        </p>
                      </li>
                      <li>
                        <h4>
                          Rifle 101 <span>4PM-5PM CST</span>
                        </h4>
                        <p>
                          Stoddard’s - Midtown <span>Mitch O.</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card-body">
                <h3>PRIVATE TRAINING</h3>
                <div className="triangle-top">
                  <div className="body-text  body-listing body-listing scrolling">
                    <ul>
                      <li>
                        <h4>
                          Handgun 101 <span>1PM-2PM CST</span>
                        </h4>
                        <p>
                          Stoddard’s - Midtown <span>Mitch O.</span>
                        </p>
                      </li>
                      <li>
                        <h4>
                          Rifle 101 <span>2PM-3PM CST</span>
                        </h4>
                        <p>
                          Stoddard’s - Midtown <span>Mitch O.</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="progress-list-user triangle-card ling-set">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <h3>ON-RANGE OVERVIEW</h3>
                  </div>
                  <div className="col-md-8">
                    <div className="all-user">
                      <UserProcessing
                        imagesCard="../assets/icon/boking_calendar.svg"
                        userHeading="Bookings & Reservation"
                        userTitle="25"
                      />
                      <UserProcessing
                        imagesCard="../assets/icon/new_guests.svg"
                        userHeading="New Guests"
                        userTitle="11"
                      />
                      <UserProcessing
                        imagesCard="../assets/icon/bays_available.svg"
                        userHeading="Bays Available "
                        userTitle="3"
                      />
                      <UserProcessing
                        imagesCard="../assets/icon/boking_calendar.svg"
                        userHeading="Lanes Available"
                        userTitle="18"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Today;
