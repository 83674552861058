import React, { useState } from 'react';
import ButtonRemoveImage from './ButtonRemoveImage';
import Label from './Label';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { getItem } from '../Utils/LocalStorage';
import { emailRegExp, phoneRegExp } from '../Utils/common';
import axiosInstance from '../Utils/axiosInstance';
import Toast from '../Utils/Toast';
import {
  ADD_TRAINER_ENDPOINT,
  RANGE_ASSOCIATE_TRAINER_ENDPOINT,
} from '../Utils/Endpoints';
import ButtonLoader from './ButtonLoader';
import PropTypes from 'prop-types';
import AddressAutocomplete from './AddressAutocomplete';
import { addAddress, uploadFile } from '../Utils/upload';
import ImageUploadInput from './ImageUploadInput';
import { USER_CLASS } from '../Utils/ModelClasses';
import { USER_PROFILE_PICTURE_TYPE } from '../Utils/UploadType';

// Validation schema
const TrainerRangeSchema = Yup.object().shape({
  userName: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid email address')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  password: Yup.string().required('Required'),
  dob: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
  bio: Yup.string().required('Required'),
  skillLevel: Yup.string().required('Required'),
  phoneNumber: Yup.string()
    .required('Required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
});

const TrainersModal = ({ fetchTrainerRangeList }) => {
  const [userName, setUserName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [bio, setBio] = useState('');
  const [skillLevel, setSkillLevel] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [addressObject, setAddressObject] = useState({});
  const [file, setFile] = useState(null);

  const rangeId = getItem('rangeId');

  const validate = () => {
    try {
      TrainerRangeSchema.validateSync(
        {
          userName,
          firstName,
          lastName,
          email,
          password,
          dob,
          gender,
          bio,
          skillLevel,
          phoneNumber,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      const response = await axiosInstance.post(ADD_TRAINER_ENDPOINT, {
        username: userName,
        email: email,
        phone_number: phoneNumber,
        first_name: firstName,
        last_name: lastName,
        dob: dob,
        gender: gender,
        bio: bio,
        password: password,
      });
      if (response.data.code === 200) {
        let requestAddressObj = { ...addressObject };
        requestAddressObj.contact_number = phoneNumber;
        requestAddressObj.contact_person_name = firstName + ' ' + lastName;
        if (file) {
          await uploadFile(
            USER_CLASS,
            response.data.data.id,
            USER_PROFILE_PICTURE_TYPE,
            file
          );
        }
        await addAddress(USER_CLASS, response.data.data.id, requestAddressObj);

        await handleTrainerAssociate(response.data.data.id);
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTrainerAssociate = async (id) => {
    const response = await axiosInstance.post(
      RANGE_ASSOCIATE_TRAINER_ENDPOINT + rangeId,
      {
        trainer_ids: [id],
      }
    );
    if (response.data.code === 200) {
      handleReset();
      const modalClose = document.getElementsByClassName('close-modal');
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
      fetchTrainerRangeList();
      Toast.success(response.data.message, {
        autoClose: 2000,
      });
    }
  };
  const handleReset = () => {
    setUserName('');
    setLastName('');
    setFirstName('');
    setEmail('');
    setPassword('');
    setDob('');
    setGender('');
    setBio('');
    setSkillLevel('');
    setGeneralError('');
    setErrors({});
  };

  return (
    <>
      <div
        className="modal fade"
        id="trainersModal"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                Trainers Range
              </h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body modal-height">
                <div className="row">
                  <div className="col-md-6">
                    <Label labelHeading="Username*" />
                    <div className="tickets">
                      <input
                        type="text"
                        className="form-control input-theme"
                        onChange={(e) => setUserName(e.target.value)}
                        value={userName}
                      />
                      {errors.userName && (
                        <div className="error">{errors.userName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Phone:" />
                    <div className="tickets">
                      <input
                        type="phone"
                        className="form-control input-theme"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      {errors.phoneNumber && (
                        <div className="error">{errors.phoneNumber}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="First Name*" />
                    <div className="tickets">
                      <input
                        type="text"
                        className="form-control input-theme"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                      />
                      {errors.firstName && (
                        <div className="error">{errors.firstName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Last Name*" />
                    <div className="tickets">
                      <input
                        type="text"
                        className="form-control input-theme"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                      />
                      {errors.lastName && (
                        <div className="error">{errors.lastName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Email*" />
                    <div className="tickets">
                      <input
                        type="email"
                        className="form-control input-theme"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                      {errors.email && (
                        <div className="error">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Password*" />
                    <div className="tickets">
                      <input
                        type="password"
                        className="form-control input-theme"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                      {errors.password && (
                        <div className="error">{errors.password}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Dob" />
                    <div className="tickets">
                      <input
                        type="date"
                        className="form-control input-theme"
                        onChange={(e) => setDob(e.target.value)}
                        value={dob}
                      />
                      {errors.dob && <div className="error">{errors.dob}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Gender*" />
                    <div className="select-input tickets">
                      <select
                        name="Gender"
                        id="Gender*"
                        className="form-select mb-0"
                        onChange={(e) => setGender(e.target.value)}
                        value={gender}
                      >
                        <option value="">Select an option</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="OTHER">Other</option>
                      </select>
                      {errors.gender && (
                        <div className="error">{errors.gender}</div>
                      )}
                    </div>
                  </div>
                  <AddressAutocomplete handleAddress={setAddressObject} />
                  <div className="col-md-6 ">
                    <Label labelHeading="Bio" />
                    <div className="tickets-2">
                      <textarea
                        className="form-control input-theme"
                        rows="3"
                        onChange={(e) => setBio(e.target.value)}
                        value={bio}
                      ></textarea>
                      {errors.bio && <div className="error">{errors.bio}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Skill level*" />
                    <div className="select-input tickets">
                      <select
                        name="Skill"
                        id="Skill"
                        className="form-select mb-0"
                        onChange={(e) => setSkillLevel(e.target.value)}
                        value={skillLevel}
                      >
                        <option value="">Select an option</option>
                        <option value="Novice/Never Ever">
                          Novice/Never Ever
                        </option>
                        <option value="Beginner/In-training">
                          Beginner/In-training
                        </option>
                        <option value="Proficient/Intermediate">
                          Proficient/Intermediate
                        </option>
                        <option value="Advanced/Specialist">
                          Advanced/Specialist
                        </option>
                        <option value="Professional">Professional</option>
                      </select>
                      {errors.skillLevel && (
                        <div className="error">{errors.skillLevel}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <Label labelHeading="Logo" />
                    <ImageUploadInput setFile={setFile} />
                  </div>
                </div>
                {generalError && (
                  <p className="error text-center mt-3">{generalError}</p>
                )}
              </div>
              <div className="modal-footer border-0 justify-content-center">
                <div className="button-range-create mt-5">
                  <ButtonLoader
                    Heading="SAVE"
                    active="orange-outline"
                    bgChange="white-bg w-pd-add save-w"
                    type="submit"
                    isLoading={isLoading}
                  >
                    Save
                  </ButtonLoader>

                  <Link
                    to="#"
                    className="close-modal"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    <ButtonRemoveImage
                      Heading="Cancel"
                      active="gary-light-outline"
                      bgChange="gary-bg w-pd-add"
                    />
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

TrainersModal.propTypes = {
  fetchTrainerRangeList: PropTypes.func,
};

export default TrainersModal;
