import { Link } from 'react-router-dom';
import Label from '../Label';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import axiosInstance from '../../Utils/axiosInstance';
import { emailRegExp, phoneRegExp } from '../../Utils/common';
import { RANGE_ENDPOINT } from '../../Utils/Endpoints';
import RichTextEditor from '../RichTextEditor';
import ButtonLoader from '../ButtonLoader';
import Toast from '../../Utils/Toast';
import PropTypes from 'prop-types';
import { setCreateRangeData } from '../../Redux/features/ranges/rangeSlice';
import { useDispatch, useSelector } from 'react-redux';
import ButtonRemoveImage from '../ButtonRemoveImage';

// Validation schema
const CreateRangeSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  subHeading: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid email address')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  phoneNumber: Yup.string()
    .required('Required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
  policy: Yup.string().required('Required'),
  membershipPolicy: Yup.string().required('Required'),
  isShotproPartner: Yup.string().required('Required'),
});

const CreateRange = ({ rangeID, setRangeID, handleNextStep }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [policy, setPolicy] = useState('');
  const [membershipPolicy, setMembershipPolicy] = useState('');
  const [isShotproPartner, setIsShotproPartner] = useState(false);
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');

  const rangeData = useSelector((state) => state.range.createRange);
  const dispatch = useDispatch();

  const validate = () => {
    try {
      CreateRangeSchema.validateSync(
        {
          name,
          subHeading,
          description,
          email,
          phoneNumber,
          policy,
          membershipPolicy,
          isShotproPartner,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      if (!rangeID) {
        const response = await axiosInstance.post(RANGE_ENDPOINT, {
          name: name,
          sub_heading: subHeading,
          description: description,
          email: email,
          phone_number: phoneNumber,
          policy: policy,
          membership_policy: membershipPolicy,
          is_shotpro_partner: isShotproPartner,
        });
        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        dispatch(setCreateRangeData(response.data.data));

        setRangeID(response.data.data.id);
      }
      Toast.success('Range created.', {
        autoClose: 2000,
      });
      handleNextStep();
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      if (rangeID) {
        const response = await axiosInstance.patch(
          RANGE_ENDPOINT + '/' + rangeID,
          {
            name: name,
            sub_heading: subHeading,
            description: description,
            email: email,
            phone_number: phoneNumber,
            policy: policy,
            membership_policy: membershipPolicy,
            is_shotpro_partner: isShotproPartner,
          }
        );
        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        dispatch(setCreateRangeData(response.data.data));

        setRangeID(response.data.data.id);
      }
      Toast.success('Range created.', {
        autoClose: 2000,
      });
      handleNextStep();
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(rangeData).length > 0) {
      setSubHeading(rangeData?.sub_heading);
      setName(rangeData?.name);
      setDescription(rangeData?.description);
      setIsShotproPartner(rangeData?.is_shotpro_partner);
      setMembershipPolicy(rangeData?.membership_policy);
      setPhoneNumber(rangeData?.phone_number);
      setPolicy(rangeData?.policy);
      setEmail(rangeData?.email);
    } else {
      handleReset();
    }
  }, [rangeData]);

  const handleReset = () => {
    setSubHeading('');
    setName('');
    setDescription('');
    setIsShotproPartner(false);
    setMembershipPolicy('');
    setPhoneNumber('');
    setPolicy('');
    setEmail('');
    setGeneralError('');
    setErrors({});
  };

  return (
    <>
      <div className="employees employ-set">
        <h3 className="card-top-2">General</h3>
      </div>
      <form
        className="triangle-top"
        onSubmit={rangeID ? handleUpdate : handleSubmit}
      >
        <div className="body-text  text-start">
          <div className="row">
            <div className="col-md-12">
              <Label labelHeading="Name" />
              <div className="tickets">
                <input
                  type="text"
                  className="form-control input-theme"
                  placeholder=""
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {errors.name && <div className="error">{errors.name}</div>}
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <Label labelHeading="Description" />
              <div className="tickets-2">
                <RichTextEditor
                  value={description}
                  setEditorData={setDescription}
                  index="description"
                />
                {errors.description && (
                  <div className="error">{errors.description}</div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <Label labelHeading="Sub Heading" />
              <div className="tickets">
                <input
                  type="text"
                  className="form-control input-theme"
                  placeholder=""
                  value={subHeading}
                  onChange={(e) => setSubHeading(e.target.value)}
                />
                {errors.subHeading && (
                  <div className="error">{errors.subHeading}</div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <Label labelHeading="Phone" />
              <div className="tickets">
                <input
                  type="tell"
                  className="form-control input-theme"
                  placeholder=""
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {errors.phoneNumber && (
                  <div className="error">{errors.phoneNumber}</div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <Label labelHeading="Email" />
              <div className="tickets">
                <input
                  type="email"
                  className="form-control input-theme"
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>
            </div>
            <div className="col-md-6">
              <Label labelHeading="Policy" />
              <div className="tickets-2">
                <RichTextEditor
                  value={policy}
                  setEditorData={setPolicy}
                  index="policy"
                />
                {errors.policy && <div className="error">{errors.policy}</div>}
              </div>
            </div>
            <div className="col-md-6 mt-3 mt-md-0">
              <Label labelHeading="Membership Policy" />
              <div className="tickets-2">
                <RichTextEditor
                  value={membershipPolicy}
                  setEditorData={setMembershipPolicy}
                  index="membershipPolicy"
                />
                {errors.membershipPolicy && (
                  <div className="error">{errors.membershipPolicy}</div>
                )}
              </div>
            </div>
            <div className="col-12 mt-3">
              <Label labelHeading="Shotpro Partner" />
              <div className="form-switch text-left mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={isShotproPartner}
                  onChange={(e) => setIsShotproPartner(e.target.checked)}
                />
                {errors.isShotproPartner && (
                  <div className="error">{errors.isShotproPartner}</div>
                )}
              </div>
            </div>
            {generalError && (
              <p className="error text-center mt-3">{generalError}</p>
            )}
            <div className="d-flex mt-3 gap-3">
              <ButtonLoader
                type="submit"
                bgChange={`white-bg  save-w `}
                active="orange-outline"
                isLoading={isLoading}
              >
                {rangeID ? 'Update' : 'Save'}
              </ButtonLoader>
              {rangeID && (
                <Link to="#" className="button-width" onClick={handleNextStep}>
                  <ButtonRemoveImage
                    Heading="Next"
                    active="orange-outline"
                    bgChange="white-bg w-pd-add"
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
CreateRange.propTypes = {
  rangeID: PropTypes.string,
  setRangeID: PropTypes.func,
  handleNextStep: PropTypes.func,
};

export default CreateRange;
