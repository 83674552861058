import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonAdd from '../Components/ButtonAdd';
import HexagonSize from '../Components/HexagonSize';
import DeleteModal from '../Components/DeleteModal';
import { PRODUCT_LIST_ENDPOINT } from '../Utils/Endpoints';
import Logger from '../Utils/Logger';
import RangeNotFound from '../Components/RangeNotFound';
import SkeletonLoader from '../Components/SkeletonLoader';
import Pagination from '../Components/Pagination';
import axiosInstance from '../Utils/axiosInstance';
import { getItem } from '../Utils/LocalStorage';

const Products = () => {
  const [search, setSearch] = useState('');
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const rangeId = getItem('rangeId');
  const [selectedData, setSelectedData] = useState({});

  // Pagination
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchProductList();
  }, [search, currentPage]);

  const fetchProductList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        PRODUCT_LIST_ENDPOINT +
          `${rangeId}?per_page=${itemsPerPage}&page=${currentPage}&search=${search}`
      );
      if (response.data.code === 200) {
        setTotalItems(response.data.data.total);
        setProductList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">All Products</h3>
                  <div className="all-date-selected">
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setCurrentPage(1);
                        }}
                        value={search}
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                    <Link to="/add-form" className="button-width">
                      <ButtonAdd
                        images="../assets/icon/plaus_icon.svg"
                        Heading="ADD PRODUCT"
                        active="orange-outline"
                        bgChange="white-bg"
                      />
                    </Link>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    {productList?.length === 0 && !isLoading && (
                      <RangeNotFound title="Products" />
                    )}
                    {isLoading && <SkeletonLoader />}
                    {productList?.length > 0 && !isLoading && (
                      <>
                        <div className="table-responsive w-100">
                          <table className="table table-time-gun-create mobile-responsive">
                            <thead>
                              <tr>
                                <th>IMAGES</th>
                                <th>PRODUCT NAME</th>
                                <th>STOCK</th>
                                <th>ACTIVE</th>
                                <th>FEATURED</th>
                                <th>ACTIONS</th>
                              </tr>
                            </thead>
                            <tbody className="table-time-gun-create__old-even">
                              {productList?.map((item, index) => (
                                <tr key={index}>
                                  <td className="td-left">
                                    <HexagonSize
                                      imageUser="../assets/images/Headshot.jpg"
                                      hexBg="m-lg-auto"
                                    />
                                  </td>
                                  <td data-label="Product Name">
                                    <p className="color-black">
                                      {item?.seller_products[0]?.name}
                                    </p>
                                    <p>
                                      {item?.entity_has_categories?.length >
                                        0 &&
                                        item?.entity_has_categories?.map(
                                          (category, index) => (
                                            <>
                                              {category?.category.name}
                                              {index <
                                              item?.entity_has_categories
                                                ?.length -
                                                1
                                                ? ', '
                                                : ''}
                                            </>
                                          )
                                        )}
                                    </p>
                                  </td>
                                  <td data-label="Stock">
                                    {item?.seller_products && (
                                      <>
                                        {item?.seller_products[0]?.stock !==
                                        null
                                          ? item?.seller_products[0]?.stock
                                          : '-'}
                                      </>
                                    )}
                                  </td>
                                  <td data-label="Active">
                                    <div className="form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={
                                          item?.seller_products[0]?.is_active
                                        }
                                        disabled
                                      />
                                    </div>
                                  </td>
                                  <td data-label="Status">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={item?.is_featured}
                                      id="active"
                                      disabled
                                    />
                                    <label
                                      className="form-check-label color-theme ps-1"
                                      htmlFor="active"
                                    >
                                      Active
                                    </label>
                                  </td>
                                  <td className="td-left">
                                    <div className="edit-user-id">
                                      <Link
                                        to="/add-form"
                                        state={{
                                          product: item,
                                        }}
                                        className="or-col border-theme"
                                      >
                                        <img
                                          src="../assets/icon/edit.svg"
                                          alt=""
                                        />
                                      </Link>

                                      <Link
                                        to="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteModal"
                                        className="dan-col border-red"
                                        onClick={() =>
                                          setSelectedData({
                                            id: item.id,
                                            type: 'product_list',
                                          })
                                        }
                                      >
                                        <img
                                          src="../assets/icon/delete.svg"
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <Pagination
                          itemsPerPage={itemsPerPage}
                          totalItems={totalItems}
                          currentPage={currentPage}
                          onPageChange={handlePageChange}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <DeleteModal
              selectedData={selectedData}
              handleUpdateList={fetchProductList}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
