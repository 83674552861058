import React from 'react';

const RangeActivities = () => {
  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">Range Activities</h3>
                  <div className="all-date-selected">
                    <div className="date-input tickets">
                      <input
                        type="date"
                        className="form-control mb-0"
                        placeholder="Event Start Date"
                      />
                    </div>
                    <div className="date-input tickets">
                      <input
                        type="time"
                        className="form-control mb-0"
                        placeholder="Event Start Date"
                      />
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="table-responsive w-100">
                          <table className="table table-user-gan table-blocker">
                            <thead>
                              <tr>
                                <th>Bay 1 - Midtown</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="bg-color-theme">1</td>
                              </tr>
                              <tr>
                                <td className="bg-color-red">2</td>
                              </tr>
                              <tr>
                                <td className="bg-color-theme">3</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="table-responsive w-100">
                          <table className="table table-user-gan table-blocker">
                            <thead>
                              <tr>
                                <th>Bay 2 - Midtown</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="bg-color-theme">1</td>
                              </tr>
                              <tr>
                                <td className="bg-color-red">2</td>
                              </tr>
                              <tr>
                                <td className="bg-color-theme">3</td>
                              </tr>
                              <tr>
                                <td className="bg-color-theme">4</td>
                              </tr>
                              <tr>
                                <td className="bg-color-red">5</td>
                              </tr>
                              <tr>
                                <td className="bg-color-theme">6</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="table-responsive w-100">
                          <table className="table table-user-gan table-blocker">
                            <thead>
                              <tr>
                                <th>Bay 3 - Midtown</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="bg-color-theme">1</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="table-responsive w-100">
                          <table className="table table-user-gan table-blocker">
                            <thead>
                              <tr>
                                <th>Bay 10 - Midtown</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="bg-color-theme">1</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="table-responsive w-100">
                          <table className="table table-user-gan table-blocker">
                            <thead>
                              <tr>
                                <th>Test Bay12</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="bg-color-theme">1</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="table-responsive">
                          <table className="table table-user-gan table-blocker">
                            <thead>
                              <tr>
                                <th>Test Bay2</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="bg-color-theme">1</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RangeActivities;
