import React, { useState } from 'react';
import ButtonRemoveImage from './ButtonRemoveImage';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axiosInstance from '../Utils/axiosInstance';
import { RANGE_OPERATION_HOUR_ENDPOINT } from '../Utils/Endpoints';
import { getItem } from '../Utils/LocalStorage';
import Toast from '../Utils/Toast';
import ButtonLoader from './ButtonLoader';
import PropTypes from 'prop-types';
import { RANGE_CLASS } from '../Utils/ModelClasses';
import { useSelector } from 'react-redux';

// Validation schema
const OperationHoursSchema = Yup.object().shape({
  day: Yup.string().required('Required'),
  startTime: Yup.string().required('Required'),
  endTime: Yup.string().required('Required'),
});

const OperationHoursModal = ({ handleUpdateList }) => {
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const rangeData = useSelector((state) => state.range.createRange);
  const rangeId =
    rangeData && Object.keys(rangeData)?.length > 0
      ? rangeData?.id
      : getItem('rangeId');
  const [operationHourList, setOperationHourList] = useState([
    {
      id: 'hour_1',
      day: '',
      startTime: '',
      endTime: '',
      isOpen: false,
      disabled: false,
    },
  ]);

  const validate = () => {
    try {
      const data = operationHourList[operationHourList.length - 1];
      const validateData = {
        day: data?.day,
        startTime: data?.startTime,
        endTime: data?.endTime,
      };
      OperationHoursSchema.validateSync(validateData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      const response = await axiosInstance.post(RANGE_OPERATION_HOUR_ENDPOINT, {
        owner_class: RANGE_CLASS,
        owner_id: rangeId,
        day: operationHourList[operationHourList.length - 1].day,
        start: operationHourList[operationHourList.length - 1].startTime,
        end: operationHourList[operationHourList.length - 1].endTime,
        is_open: operationHourList[operationHourList.length - 1].isOpen,
      });
      if (response.data.code === 200) {
        setOperationHourList((prevList) => {
          const lastIndex = prevList.length - 1;
          prevList[lastIndex].id = response?.data?.data?.id;
          return [...prevList];
        });
        addNewRow();
        // const modalClose = document.getElementsByClassName(
        //   'close-operation-hour-modal'
        // );
        // for (let i = 0; i < modalClose.length; i++) {
        //   modalClose[i].click();
        // }
        handleUpdateList(response?.data?.data, 'ADD');
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOperationHourList([
      {
        id: 'hour_1',
        day: '',
        startTime: '',
        endTime: '',
        isOpen: false,
        disabled: false,
      },
    ]);
    setGeneralError('');
    setErrors({});
  };

  const addNewRow = () => {
    setOperationHourList((prevList) => [
      ...prevList,
      {
        id: 'hour_' + (prevList.length + 1),
        day: '',
        startTime: '',
        endTime: '',
        isOpen: false,
      },
    ]);
  };

  const handleInputChange = (id, field, value) => {
    setOperationHourList((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  return (
    <>
      <div
        className="modal fade"
        id="OperationHoursModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                Operation Hours
              </h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body modal-height">
                <div className="hours">
                  <div className="table-responsive w-100">
                    <table className="table table-time-gun-create mobile-responsive time-select">
                      <thead>
                        <tr>
                          <th>Day</th>
                          <th>Open Time</th>
                          <th>Close Time</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="table-time-gun-create__old-even time-o-c">
                        {operationHourList.map((item, index) => (
                          <>
                            {typeof item.id === 'number' ? (
                              <tr key={index}>
                                <td data-label="Day">
                                  <p>{item.day}</p>
                                </td>
                                <td data-label="Open Time">
                                  <p>{item.startTime}</p>
                                </td>
                                <td data-label="Close Time">
                                  <p>{item.endTime}</p>
                                </td>
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={item.isOpen}
                                    />
                                    <label className="form-check-label">
                                      Open
                                    </label>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <tr key={index}>
                                <td data-label="Day">
                                  <div className="select-input tickets">
                                    <select
                                      className="form-select mb-0 form-select-white"
                                      onChange={(e) =>
                                        handleInputChange(
                                          item.id,
                                          'day',
                                          e.target.value
                                        )
                                      }
                                      value={item.day}
                                      required
                                      disabled={typeof item.id === 'number'}
                                    >
                                      <option value="">Select Day</option>
                                      <option value="MONDAY">MONDAY</option>
                                      <option value="TUESDAY">TUESDAY</option>
                                      <option value="WEDNESDAY">
                                        WEDNESDAY
                                      </option>
                                      <option value="THURSDAY">THURSDAY</option>
                                      <option value="FRIDAY">FRIDAY</option>
                                      <option value="SATURDAY">SATURDAY</option>
                                      <option value="SUNDAY">SUNDAY</option>
                                    </select>
                                    {errors.day && (
                                      <div className="error">{errors.day}</div>
                                    )}
                                  </div>
                                </td>
                                <td data-label="Open Time">
                                  <div className="date-input tickets">
                                    <input
                                      type="time"
                                      className="form-control mb-0"
                                      value={item.startTime}
                                      required
                                      onChange={(e) =>
                                        handleInputChange(
                                          item.id,
                                          'startTime',
                                          e.target.value
                                        )
                                      }
                                      step={1}
                                      disabled={typeof item.id === 'number'}
                                    />
                                    {errors.startTime && (
                                      <div className="error">
                                        {errors.startTime}
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td data-label="Close Time">
                                  <div className="date-input tickets">
                                    <input
                                      type="time"
                                      className="form-control mb-0"
                                      onChange={(e) =>
                                        handleInputChange(
                                          item.id,
                                          'endTime',
                                          e.target.value
                                        )
                                      }
                                      value={item.endTime}
                                      required
                                      step={1}
                                      disabled={typeof item.id === 'number'}
                                    />
                                    {errors.endTime && (
                                      <div className="error">
                                        {errors.endTime}
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleInputChange(
                                          item.id,
                                          'isOpen',
                                          e.target.checked
                                        )
                                      }
                                      checked={item.isOpen}
                                      disabled={typeof item.id === 'number'}
                                    />
                                    <label className="form-check-label">
                                      Open
                                    </label>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                        {/* <tr>
                          <td data-label="Day">
                            <div className="select-input tickets">
                              <select
                                className="form-select mb-0 form-select-white"
                                onChange={(e) => setDay(e.target.value)}
                                value={day}
                                required
                              >
                                <option value="">Select Day</option>
                                <option value="MONDAY">MONDAY</option>
                                <option value="TUESDAY">TUESDAY</option>
                                <option value="WEDNESDAY">WEDNESDAY</option>
                                <option value="THURSDAY">THURSDAY</option>
                                <option value="FRIDAY">FRIDAY</option>
                                <option value="SATURDAY">SATURDAY</option>
                                <option value="SUNDAY">SUNDAY</option>
                              </select>
                              {errors.day && (
                                <div className="error">{errors.day}</div>
                              )}
                            </div>
                          </td>
                          <td data-label="Open Time">
                            <div className="">
                              <div className="date-input tickets">
                                <input
                                  type="time"
                                  className="form-control mb-0"
                                  placeholder=""
                                  value={startTime}
                                  required
                                  onChange={(e) => setStartTime(e.target.value)}
                                  step={1}
                                />
                                {errors.startTime && (
                                  <div className="error">
                                    {errors.startTime}
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td data-label="Close Time">
                            <div className="">
                              <div className="date-input tickets">
                                <input
                                  type="time"
                                  className="form-control mb-0"
                                  placeholder=""
                                  onChange={(e) => setEndTime(e.target.value)}
                                  value={endTime}
                                  required
                                  step={1}
                                />
                                {errors.endTime && (
                                  <div className="error">{errors.endTime}</div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="active"
                                  onChange={(e) => setIsOpen(e.target.checked)}
                                  checked={isOpen}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="active"
                                >
                                  Open
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {generalError && (
                <p className="error text-center">{generalError}</p>
              )}
              <div className="modal-footer border-0 justify-content-center">
                <div className="button-range-create mt-5">
                  <ButtonLoader
                    Heading="SAVE"
                    active="orange-outline"
                    bgChange="white-bg w-pd-add save-w"
                    type="submit"
                    isLoading={isLoading}
                  >
                    Save
                  </ButtonLoader>

                  <Link
                    to="#"
                    className="close-operation-hour-modal"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    <ButtonRemoveImage
                      Heading="Cancel"
                      active="gary-light-outline"
                      bgChange="gary-bg w-pd-add"
                    />
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

OperationHoursModal.propTypes = {
  handleUpdateList: PropTypes.func,
};

export default OperationHoursModal;
