import React from 'react';
import { Link } from 'react-router-dom';

const Booking = () => {
  return (
    <div className="outer-border">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card-body">
              <div className="employees employ-set">
                <h3 className="card-top-2">Range Reservations</h3>
                <div className="all-date-selected">
                  <div className="date-input tickets">
                    {/* <img src="../assets/images/clander-2.png" alt="" /> */}
                    <input
                      type="date"
                      className="form-control mb-0"
                      placeholder="dd-mm-yyyy"
                      required
                    />
                  </div>
                  <div className="input-search tickets">
                    <input
                      type="phone"
                      className="form-control input-theme input-pd mb-0"
                      placeholder="Search User by Phone"
                    />
                  </div>
                  <div className="input-search-items tickets">
                    <input
                      type="search"
                      className="form-control input-theme mb-0"
                      placeholder="Search..."
                    />
                    <img src="../assets/icon/search_icon.svg" alt="" />
                  </div>
                </div>
              </div>
              <div className="triangle-top">
                <div className="body-text">
                  <div className="table-responsive">
                    <table className="table table-time-gun-create mobile-responsive mobile pb-5">
                      <thead className="head-left">
                        <tr>
                          <th>Status</th>
                          <th>Order</th>
                          <th>Customer</th>
                          <th>No of Participants</th>
                          <th>Total</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody className="table-time-gun-create__old-even text-start mobile__old-even">
                        <tr>
                          <td data-label="Status">
                            <p className="color-black text-decoration-underline text-md-center center-left">
                              Processing
                            </p>
                          </td>
                          <td data-label="Order">
                            <ul className="mb-0 text-md-center">
                              <li>#45870</li>
                              <li>
                                Stoddard’s Range and <br />
                                Guns – Atlanta Midtown
                              </li>
                              <li>Lane 10</li>
                            </ul>
                          </td>
                          <td data-label="Customer">
                            <div className="text-md-center">
                              <p className="color-black">
                                testxname testlastname
                              </p>
                              <Link
                                to="mailto:kapil.bisht@yatilabs.com"
                                className="color-gray"
                              >
                                bisht@yatilabs.com
                              </Link>
                              <p className="color-gray">+919915187369</p>
                            </div>
                          </td>
                          <td data-label="No of Participants">
                            <p className="color-black text-md-center">1</p>
                          </td>
                          <td data-label="Total" align="center">
                            <p className="color-black text-md-center">$0.00</p>
                          </td>
                          <td data-label="Date">
                            <p className="color-black table-button-width text-md-center">
                              September 4, 2023 11:00 am
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td data-label="Status">
                            <p className="color-red text-decoration-underline text-md-center">
                              Refunded
                            </p>
                          </td>
                          <td data-label="Order">
                            <ul className="mb-0 text-md-center">
                              <li>#45870</li>
                              <li>
                                Stoddard’s Range and <br />
                                Guns – Atlanta Midtown
                              </li>
                              <li>Lane 10</li>
                            </ul>
                          </td>
                          <td data-label="Customer">
                            <div className="text-md-center">
                              <p className="color-black">
                                testxname testlastname
                              </p>
                              <Link
                                to="mailto:kapil.bisht@yatilabs.com"
                                className="color-gray"
                              >
                                bisht@yatilabs.com
                              </Link>
                              <p className="color-gray">+919915187369</p>
                            </div>
                          </td>
                          <td data-label="No of Participants">
                            <p className="color-black text-md-center">1</p>
                          </td>
                          <td data-label="Total" align="center">
                            <p className="color-black text-md-center">$0.00</p>
                          </td>
                          <td data-label="Date">
                            <p className="color-black table-button-width text-md-center">
                              September 4, 2023 11:00 am
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
