import React from 'react';
import { Link } from 'react-router-dom';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import HexagonSize from '../Components/HexagonSize';
import HexLine from '../Components/HexLine';

const IdVerificationList = () => {
  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body ">
                <div className="employees employ-set">
                  <h3 className="card-top-2">ID VERIFICATION</h3>
                  <div className="all-date-selected">
                    <div className="date-input tickets">
                      <input
                        type="date"
                        className="form-control mb-0"
                        placeholder="Event Start Date"
                      />
                    </div>
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text srb-ver">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-home"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="examination">
                              <div className="table-responsive w-100">
                                <table className="table table-time-gun-create mobile-responsive mobile">
                                  <thead>
                                    <tr>
                                      <th className="d-none"></th>
                                      <th>Name</th>
                                      <th>ID Type</th>
                                      <th>Date Request</th>
                                      <th>Documents</th>
                                      <th>Verified</th>
                                      <th>Admin Log</th>
                                    </tr>
                                  </thead>
                                  <tbody className="table-time-gun-create__old-even mobile__old-even">
                                    <tr>
                                      <td className="mob-tab-view td-left">
                                        <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                      </td>
                                      <td data-label="Name">
                                        <span className="user-n-2 mob-tab-view-2">
                                          <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                          Billy Squire
                                        </span>
                                        <span className="mob-tab-view">
                                          Billy Squire
                                        </span>
                                      </td>
                                      <td data-label="Id Type">
                                        <img
                                          src="../assets/icon/id_verification.svg"
                                          alt=""
                                        />
                                        <span className="color-black-fw ps-2 d-block">
                                          STATE ISSUED
                                        </span>
                                      </td>
                                      <td data-label="Date Request">
                                        <p>
                                          January 1, 2023 <br />
                                          10:21 AM CST
                                        </p>
                                      </td>
                                      <td data-label="Documents">
                                        <img
                                          src="../assets/icon/id_verification_active.svg"
                                          alt=""
                                        />
                                      </td>
                                      <td
                                        data-label="Verified"
                                        className="table-button-width"
                                      >
                                        <Link
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                        >
                                          <ButtonRemoveImage
                                            Heading="PENDING"
                                            bgChange="bg-orange"
                                          />
                                        </Link>
                                      </td>
                                      <td data-label="Admin Log">Billy</td>
                                    </tr>
                                    <tr>
                                      <td className="mob-tab-view td-left">
                                        <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                      </td>
                                      <td data-label="Name">
                                        <span className="user-n-2 mob-tab-view-2">
                                          <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                          Billy Squire
                                        </span>
                                        <span className="mob-tab-view">
                                          Billy Squire
                                        </span>
                                      </td>
                                      <td data-label="Id Type">
                                        <img
                                          src="../assets/icon/id_verification.svg"
                                          alt=""
                                        />
                                        <span className="color-black-fw ps-2 d-block">
                                          STATE ISSUED
                                        </span>
                                      </td>
                                      <td data-label="Date Request">
                                        <p>
                                          January 1, 2023 <br />
                                          10:21 AM CST
                                        </p>
                                      </td>
                                      <td data-label="Documents">
                                        <img
                                          src="../assets/icon/id_verification_active.svg"
                                          alt=""
                                        />
                                      </td>
                                      <td
                                        data-label="Verified"
                                        className="table-button-width"
                                      >
                                        <Link
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                        >
                                          <ButtonRemoveImage
                                            Heading="PENDING"
                                            active=""
                                            bgChange="bg-orange"
                                          />
                                        </Link>
                                      </td>
                                      <td data-label="Admin Log">Billy</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="v-pills-profile">
                        2
                      </div>
                      <div className="tab-pane fade" id="v-pills-messages">
                        3
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content modal-table">
            <div className="modal-header table-header">
              <div aria-label="Close" data-bs-dismiss="modal">
                <HexLine
                  hexBg="bg-bo-li-th"
                  hexOuterLine="bg-dark-bc"
                  imageUserTwo="../assets/icon/cross_icon.svg"
                />
              </div>
              <h5>ID VERIFICATION</h5>
            </div>
            <div className="modal-body identity-card">
              <div className="identity-card__body identity-card__border">
                {/* <div className="hex hex-3 m-auto">
                                    <img src="../assets/images/seconde.jpeg" />
                                </div> */}
                <HexagonSize
                  hexOuterLine="bg-trans"
                  imageUser="../assets/images/seconde.jpeg"
                  hexBg="bg-black bg-org m-auto"
                />
                <h4>Billy Squire</h4>
              </div>
              <div className="identity-card__body">
                <HexLine
                  hexBg="bg-bo-li-th-w m-auto"
                  hexOuterLine="bg-dark-bc"
                  imageUserTwo="../assets/icon/id_verification.svg"
                  imgSizeTwo="today-size"
                />
                <h4>STATE ISSUED ID</h4>
              </div>
            </div>
            <div className="modal-footer identity-button">
              <ButtonRemoveImage
                Heading="INCOMPLETE"
                activeColor="white-color"
                bgChange="bg-dark-gray"
                active="orange-outline"
              />
              <ButtonRemoveImage
                Heading="APPROVED"
                active="success-outline"
                bgChange="bg-dark-gray-success"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IdVerificationList;
